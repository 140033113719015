import { Box, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import Confirmation from "../../assets/Confirmation.png";
import useUserData from "../../hooks/useUserData";

const SJQPageSidebar = () => {
  const { t } = useTranslation();
  const { data } = useUserData();

  return (
    <Box
      component="aside"
      sx={{
        width: "25%",
        height: "100%",
        borderRadius: "8px",
        overflow: "hidden",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          minHeight: "150px",
          backgroundImage: `url(${Confirmation})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: "1rem",
          borderRadius: "8px 8px 0 0",
        }}
      >
        <Typography
          variant="h5"
          sx={{
            color: "#fff",
            fontSize:
              "calc(11px + (24 - 16) * ((100vw - 300px) / (1600 - 300)))",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          {data?.name || t("Manager")}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            color: "#FFFFFFB2",
            textAlign: "center",
          }}
        >
          {t("SJQ")}
        </Typography>
      </Box>
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          backgroundColor: "#F7FCF4",
          padding: "24px",
          borderRadius: "0 0 8px 8px",
        }}
      >
        <Typography
          sx={{
            color: "#3D7C65",
            fontWeight: "600",
            textAlign: "left",
            mb: 2.5,
          }}
        >
          {t("SJQInstructionPointer1")}
        </Typography>
        <Typography
          sx={{
            color: "#3D7C65",
            fontWeight: "600",
            textAlign: "left",
            mb: 2.5,
          }}
        >
          {t("SJQInstructionPointer2")}
        </Typography>
        <Typography
          sx={{
            color: "#3D7C65",
            fontWeight: "600",
            textAlign: "left",
            mb: 2.5,
          }}
        >
          {t("SJQInstructionPointer3")}
        </Typography>
      </Box>
    </Box>
  );
};

export default SJQPageSidebar;
