import {
  Alert,
  Box,
  Button,
  Snackbar,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import i18next from "i18next";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InstructionsBack from "../assets/InstructionsBack.png";
import EmployeeFeedbackSidebar from "../components/employee-feedback/EmployeeFeedbackSidebar";
import ProficiencyDescription from "../components/employee-feedback/ProficiencyDescription";
import {
  QontoConnector,
  QontoStepIcon,
} from "../components/employee-feedback/StepIconChild";
import FeedbackForm from "../components/forms/FeedbackForm";
import ProficiencyForm from "../components/forms/ProficiencyForm";
import CustomDialog from "../components/modals/CustomDialog";
import useEmployeeFeedback from "../hooks/useEmployeeFeedback";
import {
  setFeedBackPagePart,
  setFeedBackQuestionId,
  setFeedBackTotalPages,
} from "../store/FeedBack/action";

function arraysAreEqual(arr1, arr2) {
  // Check if the arrays have the same length
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  // Iterate through each element and compare
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      // If any elements are not equal, return false
      return false;
    }
  }

  // If all elements are equal, return true
  return true;
}

const EmployeeFeedbackPage = () => {
  const { t } = useTranslation();
  const { state } = useLocation();
  const lang = i18next.language;
  const dispatch = useDispatch();
  const [showGlossaryModal, setShowGlossaryModal] = useState(false);
  const feedBackData = useSelector((state) => state.feedBackReducer);
  const steps = ["Questions", "Proficiency"];
  const [CompetencyAnsData, setCompetencyAnsData] = useState(false);
  const [show, setShow] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);
  const [pageAnswers, setPageAnswers] = useState({});
  const [pagesPreviousData, setPreviousPageData] = useState(null);
  const [questionId, setQuestionId] = useState(null);

  const {
    competencyData,
    CompetencyError,
    refetchCompetency,
    isCompetencyLoading,
    isCompetencyFetching,
  } = useEmployeeFeedback({
    offset: feedBackData?.current_page,
    partner_id: state?.id,
    lang,
  });

  useEffect(() => {
    if (!!competencyData) {
      dispatch(
        setFeedBackQuestionId(competencyData?.records[0]?.question_and_page_ids)
      );
      dispatch(setFeedBackTotalPages(competencyData?.total_records));
      setQuestionId(
        competencyData?.records[0]?.question_and_page_ids
          ?.filter((t) => !t.is_page)
          ?.map((y) => y.question_id)
      );
      // setShowPart(0);
      setShow(0);
      const IsNotFiled = competencyData?.records[0]?.question_and_page_ids
        ?.filter((t) => !t.is_page)
        ?.find((y) => y?.submitted_answer_details?.submitted_answer === false);
      const isPageField =
        competencyData?.records[0]?.question_and_page_ids?.find(
          (item) => item?.title === "Proficiency Level"
        )?.submitted_answer_details?.score;
      if (IsNotFiled === undefined) {
        if (isPageField) {
          setShow(2);
        } else {
          setShow(1);
        }
      } else {
        setShow(0);
      }
    }
  }, [competencyData, dispatch]);

  useEffect(() => {
    if (!!state?.id && feedBackData?.current_page) {
      refetchCompetency();
    }
    const pagesPreviousData = feedBackData?.Feed_back_full_fill_Id?.find(
      (item) => item?.competency_id === competencyData?.records[0]?.id
    );
    if (!!pagesPreviousData) {
      setPreviousPageData(pagesPreviousData);
    }
  }, [
    feedBackData.current_page,
    lang,
    CompetencyAnsData,
    state?.id,
    dispatch,
    refetchCompetency,
    competencyData,
    feedBackData.Feed_back_full_fill_Id,
  ]);

  //----------------------------------------------Start for check form completely filled-----------------------

  useEffect(() => {
    const pageQueAnsId = Object.keys(pageAnswers).map((str) =>
      parseInt(str, 10)
    );
    if (arraysAreEqual(pageQueAnsId, questionId)) {
      setPageAnswers({
        ...pageAnswers,
        competency_id: competencyData?.records[0]?.id,
        proficiency_level: 0,
      });
    }
    if (pageAnswers?.proficiency_level && pageAnswers?.proficiency_level > 0) {
      setPageAnswers({});
    }
  }, [pageAnswers, questionId]);
  // ----------------------------------------------------------End for check form completely filled---------------------------

  const isSectionFilled = () => {
    const isCompleted = competencyData?.records[0]?.question_and_page_ids?.find(
      (item) => item?.title === "Proficiency Level"
    )?.submitted_answer_details?.submitted_answer;

    if (isCompleted) {
      const Ans = competencyData?.records[0]?.question_and_page_ids?.find(
        (item) => item?.title === "Proficiency Level"
      )?.submitted_answer_details?.score;
      if (Ans > 0) {
        setShow(2);
      }
      return Ans;
    } else {
      return 0;
    }
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${InstructionsBack})`,
        height: "78svh",
        width: "100%",
        padding: "30px",
      }}
    >
      <Box
        element="section"
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        gap={"1rem"}
        sx={{
          height: "100%",
        }}
      >
        <EmployeeFeedbackSidebar
          name={competencyData?.records[0]?.name}
          definition={competencyData?.records[0]?.competency_definition}
        />

        <Box
          element="main"
          display={"flex"}
          flexDirection={"column"}
          width={"75%"}
        >
          {feedBackData?.Feed_back_part === 0 && (
            <Box
              element="button"
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                backgroundColor: "#fff",
                padding: "0.5rem 1rem",
                borderRadius: "8px",
                cursor: "pointer",
                fontWeight: "bold",
                color: "#3D7C65",
                marginBottom: "0.5rem",
                "&:hover": {
                  backgroundColor: "#3D7C65",
                  color: "#fff",
                },
              }}
              onClick={() => setShowGlossaryModal(true)}
            >
              {t("ShowGlossary")}
            </Box>
          )}

          <CustomDialog
            open={showGlossaryModal}
            setOpen={setShowGlossaryModal}
            title={t("BelowDefProfLavel")}
            onClose={() => setShowGlossaryModal(false)}
          >
            <ProficiencyDescription isModal={true} />
          </CustomDialog>

          {feedBackData?.Feed_back_part === 1 ? (
            <ProficiencyForm
              feedBackData={feedBackData}
              setShow={setShow}
              isSectionFilled={isSectionFilled}
            />
          ) : (
            <Box
              sx={{
                display: "flex",
                width: "100%",
                overflowY: "auto",
                flexDirection: "column",
              }}
            >
              <FeedbackForm
                setCompetencyAnsData={setCompetencyAnsData}
                pagesPreviousData={pagesPreviousData}
                competencyData={competencyData}
                setPageAnswers={setPageAnswers}
                pageAnswers={pageAnswers}
              />
            </Box>
          )}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "1rem",
              padding: "0.5rem 1rem",
              borderRadius: "8px",
              backgroundColor: "#FFFFFFB2",
              minHeight: "90px ",
              overflowX: "auto",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: feedBackData?.Feed_back_part === 1 ? "pointer" : "",
              }}
            >
              <Button
                onClick={() => {
                  dispatch(setFeedBackPagePart(0));
                }}
                sx={{
                  border: "1px solid #3D7C65",
                  borderRadius: "8px",
                  margin: "auto 0",
                  padding: "0.5rem 1rem",
                  textTransform: "none",
                  backgroundColor:
                    feedBackData?.Feed_back_part === 0 ? "inherit" : "#3D7C65",
                  color:
                    feedBackData?.Feed_back_part === 0 ? "#3D7C65" : "#fff",
                  "&:hover": {
                    backgroundColor: "#2faa7d",
                  },
                  mx: 4,
                }}
              >
                <ArrowLeft
                  size="24"
                  color={
                    feedBackData?.Feed_back_part === 0 ? "#3D7C65" : "#fff"
                  }
                />{" "}
                <Typography
                  sx={{
                    color:
                      feedBackData?.Feed_back_part === 0 ? "#3D7C65" : "#fff",
                    ml: 2,
                  }}
                >
                  {t("PartOne")}
                </Typography>
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                m: 1.5,
              }}
            >
              <Stepper
                activeStep={show}
                alternativeLabel
                connector={<QontoConnector />}
              >
                {steps.map((label, i) => (
                  <Step key={label}>
                    <StepLabel
                      sx={{
                        "& .MuiStepLabel-label ": {
                          mt: "0px !important",
                          fontWeight: "600 !important",
                        },
                        "& .Mui-active": {
                          color: "#3D7C65 !important",
                        },
                        "& .Mui-completed ": {
                          color: "#161616 !important",
                          fontWeight: "600 !important",
                        },
                        mt: "0px",
                        minWidth: {
                          md: "100px",
                          lg: "200px",
                        },
                      }}
                      StepIconComponent={QontoStepIcon}
                    >
                      {t(label)}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: feedBackData?.Feed_back_part === 1 ? "" : "pointer",
              }}
            >
              <Button
                onClick={() => {
                  if (show > 0) {
                    dispatch(setFeedBackPagePart(1));
                  } else {
                    setAlertOpen(true);
                  }
                }}
                sx={{
                  border: "1px solid #3D7C65",
                  borderRadius: "8px",
                  margin: "auto 0",
                  padding: "0.5rem 1rem",
                  textTransform: "none",
                  backgroundColor:
                    feedBackData?.Feed_back_part === 1 ? "inherit" : "#3D7C65",
                  color:
                    feedBackData?.Feed_back_part === 1 ? "#3D7C65" : "#fff",
                  "&:hover": {
                    backgroundColor: "#2faa7d",
                  },
                  mx: 4,
                }}
                variant="contained"
              >
                <Typography
                  sx={{
                    color:
                      feedBackData?.Feed_back_part === 1 ? "#3D7C65" : "#fff",
                    mr: 2,
                  }}
                >
                  {t("PartTwo")}
                </Typography>{" "}
                <ArrowRight
                  size="24"
                  color={
                    feedBackData?.Feed_back_part === 1 ? "#3D7C65" : "#fff"
                  }
                />
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* Alert Snackbar */}
      <Snackbar
        open={alertOpen}
        autoHideDuration={5000}
        onClose={() => setAlertOpen(false)}
        sx={{ textAlign: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlertOpen(false)}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {t("IncompleteValues3")}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default EmployeeFeedbackPage;
