import { Box, Button, Typography } from "@mui/material";
import AssignmentPhoto from "../../assets/Assignment.png";
import InstructionsBack from "../../assets/InstructionsBack.png";
import InstructionsTitle from "../../assets/InstructionsTitle.png";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import useFormStatus from "../../hooks/useFormStatus";
import TypographyPoint from "../header/TypographyPoint";

const CompetencyInstructions = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const { formStatusRequest } = useFormStatus();

  const [page, setPage] = useState(null);

  useEffect(() => {
    const page = sessionStorage.getItem("INSTRUCTION_PAGE_FOR");
    console.log(page);

    if (page) {
      setPage(page);
    } else {
      navigate("/dashboard");
    }
  }, [navigate]);

  return (
    <Box
      sx={{
        backgroundImage: `url(${InstructionsBack})`,
        height: "88svh",
        width: "100%",
        padding: "30px",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          border: "1px solid #3D7C65",
          borderRadius: "8px",
          margin: "0px 10%",
          backgroundColor: "#F7FCF4",
        }}
      >
        <Box sx={{ padding: "0", height: "10svh", position: "relative" }}>
          <img
            src={InstructionsTitle}
            width={"100%"}
            height={"100%"}
            style={{
              borderRadius: "6px 6px 0 0",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            alt="Instructions Title"
          />
          <Box
            sx={{
              padding: "0.5rem 1rem",
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#fff",
                fontWeight: "600",
                textAlign: "center",
                zIndex: 999,
              }}
            >
              {t(location?.state ? location.state.name : "Manager")}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: "#FFFFFFB2",
                textAlign: "center",
                zIndex: 999,
              }}
            >
              {page === "professional"
                ? t("EmployeeAssignmentForm")
                : page === "uba_l1_l4"
                ? t("UBAL1L4AssessmentForm")
                : t("UBAL5L14AssessmentForm")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            overflowY: "auto",
            height: "70svh",
            padding: "0 2rem",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              margin: "10px 0",
            }}
          >
            <img src={AssignmentPhoto} height={200} alt="Assignment" />
          </Box>
          <Box>
            <Typography variant="h6">{t("Instruction")}</Typography>
            <TypographyPoint text={t("GeneralPoint1FF")} />
            <TypographyPoint text={t("GeneralPoint3FF")} />
            <Typography
              sx={{
                color: "#15134B",
                fontWeight: "400",
                marginTop: "7px",
                marginLeft: "50px",
              }}
            >
              {t("GeneralPoint3FFP1")}
            </Typography>
            <Typography
              sx={{
                color: "#15134B",
                fontWeight: "400",
                marginTop: "7px",
                marginLeft: "50px",
              }}
            >
              {t("GeneralPoint3FFP2")}
            </Typography>
          </Box>
          <Box sx={{ marginTop: "1rem" }}>
            <Typography variant="h6">{t("Note")}</Typography>
            <TypographyPoint text={t("InstructionPoint1FF")} />
            <TypographyPoint text={t("InstructionPoint2FF")} />
          </Box>
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <Button
              sx={{
                margin: "25px 0",
                bgcolor: "#3D7C65",
                padding: "6px 8px",
                fontWeight: "500",
                borderRadius: "8px",
                textTransform: "none",
                width: "280px",
                ...(location?.state &&
                location.state[`${page}_feedback_state`] === "done"
                  ? {
                      color: "#000",
                      bgcolor: "#E0E0E0",
                      pointerEvents: "none",
                      boxShadow: "none",
                    }
                  : {
                      color: "#fff",
                      bgcolor: "#3D7C65",
                      "&:hover": {
                        bgcolor: "#2faa7d !important",
                      },
                    }),
              }}
              variant="contained"
              // disabled={
              //   location?.state &&
              //   location.state[`${page}_feedback_state`] === "done"
              // }
              onClick={() => {
                formStatusRequest({
                  status: "in_progress",
                  competency_type: page,
                });
                navigate(
                  page === "professional"
                    ? "/dashboard/reviews/employee-review"
                    : page === "uba_l1_l4"
                    ? "/dashboard/forms/uba_l1_l4"
                    : "/dashboard/forms/uba_l5_l14",
                  {
                    state: location.state,
                  }
                );
              }}
            >
              {location?.state &&
              location.state[`${page}_feedback_state`] === "done"
                ? t("AssessmentCompleted")
                : t("StartAssignment")}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompetencyInstructions;
