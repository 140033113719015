import { combineReducers, createStore } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to sessionStorage for web

import feedBackReducer from "./FeedBack/reducer";
import userReducer from "./Login/reducer";
import productReducer from "./Product/reducer";
import UbaReducer from "./UBA/reducer";

const rootReducer = combineReducers({
  productReducer,
  userReducer,
  feedBackReducer,
  UbaReducer,
});

const persistConfig = {
  key: "root", // Key under which to store data in storage (e.g., sessionStorage)
  storage, // Storage engine (defaults to sessionStorage)
  // Optional options:
  whitelist: ["userReducer"], // Persist only the productReducer slice (optional)
  blacklist: ["productReducer", "feedBackReducer", "UbaReducer"], // Exclude specific reducers from persisting (optional)
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);

export default store;
