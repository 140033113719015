import {
  Alert,
  Box,
  ButtonBase,
  Container,
  Snackbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useKLead from "../hooks/useKLead";

import AssignmentPhoto from "../assets/Assignment.png";
import Confirmation from "../assets/Confirmation.png";
import InstructionsBack from "../assets/InstructionsBack.png";

import { ArrowRight } from "iconsax-react";

import { useNavigate } from "react-router-dom";
import CustomDialog from "../components/modals/CustomDialog";

import { motion } from "framer-motion";
import useFormStatus from "../hooks/useFormStatus";
const getLetter = (index) => {
  return String.fromCharCode(65 + index);
};

const KLeadPage = () => {
  const {
    t,
    isLoading,
    error,
    alert,
    setAlert,
    fetchKLeadQuestions,
    questions,
    currentPage,
    totalPages,
    handlePageChange,
    success,
    handleKLeadAnswer,
    isKLeadFinished,
    submissionModalState,
    setSubmissionModalState,
  } = useKLead();

  const { formStatusRequest } = useFormStatus();

  const navigate = useNavigate();

  const progress = (currentPage / totalPages) * 100;
  const [selectedAnswer, setSelectedAnswer] = useState(null);

  useEffect(() => {
    if (questions[currentPage - 1]) {
      setSelectedAnswer(
        questions[currentPage - 1].submitted_answer_details?.submitted_answer_id
      );
    }
  }, [currentPage, questions]);

  return (
    <Box
      sx={{
        position: "relative",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100svh",
        backgroundImage: `url(${InstructionsBack})`,
        overflow: "hidden",
      }}
    >
      <Box
        component={"img"}
        src="/klead.svg"
        alt="KLead"
        sx={{
          position: "absolute",
          zIndex: "0",
          bottom: "5rem",
          left: "-5rem",
          width: {
            xs: "30%",
            md: "35%",
            lg: "40%",
          },
          height: {
            xs: "30%",
            md: "35%",
            lg: "40%",
          },
        }}
      />
      <Container
        sx={{
          position: "relative",
          zIndex: "1",
          paddingTop: "2rem",
          maxWidth: {
            xs: "90%",
            sm: "80%",
            md: "70%",
          },
        }}
      >
        <Box
          sx={{
            backgroundImage: `url(${Confirmation})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            padding: "2rem 1rem",
            marginTop: "2.5rem",
            borderRadius: "8px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              color: "#fff",
              fontWeight: "600",
              textAlign: "center",
            }}
          >
            {t("KLeadFormTitle")}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            ml: "auto",
            gap: "1rem",
            width: {
              lg: "80%",
            },
            marginTop: "2.5rem",
          }}
        >
          <Typography
            sx={{
              position: "relative",
              display: "inline-block",
              padding: "10px",
              fontSize: "1.25rem",
              fontWeight: "600",
              color: "#3D7C65",
              "&::before": {
                content: '""',
                position: "absolute",
                top: "50%",
                left: "-10px",
                width: "0",
                height: "0",
                border: "10px solid transparent",
                borderRightColor: "#3D7C65",
                transform: "translateY(-50%) rotate(180deg)",
              },
            }}
          >
            {t("KleadQuestion")}
          </Typography>
          <Box
            key={currentPage}
            component={motion.div}
            initial={{ opacity: 0, y: 150 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
            }}
          >
            {questions[currentPage - 1]?.values.map((item, index) => {
              return (
                <Box
                  key={item.answer_id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    padding: "1rem",
                    cursor: "pointer",
                    border:
                      selectedAnswer === item.answer_id
                        ? "0.5px solid white"
                        : "0.5px solid black",
                    boxShadow:
                      selectedAnswer === item.answer_id
                        ? "0px 4px 4px rgb(255, 255, 255)"
                        : "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    backgroundColor:
                      selectedAnswer === item.answer_id
                        ? "#64ae94"
                        : "#ffffffd2",
                    transform:
                      selectedAnswer === item.answer_id
                        ? "scale(1.01)"
                        : "scale(1)",
                    transition:
                      "background-color 0.3s ease, transform 0.2s ease",
                    borderRadius: "8px",
                  }}
                  onClick={() =>
                    handleKLeadAnswer({
                      competency_id: questions[currentPage - 1].competency_id,
                      question_id: questions[currentPage - 1].question_id,
                      answer_id: item.answer_id,
                      description: questions[currentPage - 1].title,
                    })
                  }
                >
                  <Typography
                    element={"span"}
                    sx={{
                      padding: "0.5rem",
                      display: "grid",
                      placeItems: "center",
                      height: {
                        xs: "2.5rem",
                        sm: "3rem",
                      },
                      width: {
                        xs: "2.5rem",
                        sm: "3rem",
                      },
                      borderRadius: "8px",
                      color:
                        selectedAnswer === item.answer_id ? "white" : "#3D7C65",
                      border:
                        selectedAnswer === item.answer_id
                          ? "0.5px solid white"
                          : "0.5px solid #3D7C65",
                      fontSize: "1.2rem",
                      fontWeight: "800",
                    }}
                  >
                    {getLetter(index)}
                  </Typography>
                  <Typography
                    sx={{
                      color:
                        selectedAnswer === item.answer_id ? "white" : "#3D7C65",
                      fontSize: "1.3rem",
                      fontWeight: "600",
                    }}
                  >
                    {item.value}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
        {/* Alert Snackbar */}
        <Snackbar
          open={alert.open}
          autoHideDuration={5000}
          onClose={() => setAlert({ open: false, message: "" })}
          sx={{ textAlign: "center" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={() => setAlert({ open: false, message: "" })}
            severity="warning"
            sx={{ width: "100%" }}
          >
            {alert.message}
          </Alert>
        </Snackbar>
        {/* Dialog */}
        <CustomDialog
          open={submissionModalState.open}
          title={t("AssessmentSubmittedSuccessfully")}
          children={
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                padding: "2rem 0",
                justifyContent: "center",
                gap: "2rem",
              }}
            >
              <img src={AssignmentPhoto} height={150} alt="Assignment" />
              <Typography variant="h6" align="center">
                {submissionModalState.message || t("CompeletedAssessment")}
              </Typography>
              <ButtonBase
                sx={{
                  border: "1px solid #3D7C65",
                  borderRadius: "8px",
                  margin: "auto 0",
                  backgroundColor: "inherit",
                  color: "#3D7C65",
                  width: "auto",
                  padding: "1rem 2rem",
                  fontSize: "1.2rem",
                }}
                onClick={() => {
                  setSubmissionModalState({
                    open: false,
                    message: t("AssessmentSubmittedSuccessfully"),
                  });
                  navigate("/dashboard/forms", { replace: true });
                }}
              >
                {t("GoHomepage")}
              </ButtonBase>
            </Box>
          }
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "end",
            marginTop: "2rem",
          }}
        >
          {isKLeadFinished && currentPage === totalPages ? (
            <ButtonBase
              onClick={() => {
                formStatusRequest({
                  status: "done",
                  competency_type: "k-lead",
                });
                setSubmissionModalState({
                  open: true,
                  message: t("AssessmentSubmittedSuccessfully"),
                });
              }}
              sx={{
                border: "1px solid #3D7C65",
                borderRadius: "8px",
                margin: "auto 0",
                padding: "8px",
                width: "170px",
                backgroundColor: "inherit",
                color: "#3D7C65",
              }}
            >
              <Typography
                sx={{
                  color: "#3D7C65",
                  fontSize: "24px",
                }}
                mr={0.5}
              >
                {t("Submit")}
              </Typography>
            </ButtonBase>
          ) : (
            <ButtonBase
              onClick={() => handlePageChange("next")}
              disabled={currentPage === totalPages}
              sx={{
                border: "1px solid #3D7C65",
                borderRadius: "8px",
                margin: "auto 0",
                padding: "8px",
                width: "170px",
                backgroundColor:
                  currentPage === totalPages ? "inherit" : "#3D7C65",
                color: currentPage === totalPages ? "#3D7C65" : "#fff",
                "&:hover": {
                  backgroundColor:
                    currentPage === totalPages ? "inherit" : "#2faa7d",
                },
              }}
            >
              <Typography
                sx={{
                  color: currentPage === totalPages ? "#3D7C65" : "#fff",
                  fontSize: "24px",
                }}
                mr={0.5}
              >
                {t("Next")}
              </Typography>
              {!isKLeadFinished && (
                <ArrowRight
                  size="24"
                  color={currentPage === totalPages ? "#D4D4D4" : "#fff"}
                />
              )}
            </ButtonBase>
          )}
        </Box>
      </Container>

      {/* Progress Bar */}
      <Box
        sx={{
          position: "fixed",
          top: "12svh",
          width: "100%",
          height: "1.25rem",
          backgroundColor: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            left: "0.1%",
            width: `${Math.min(progress, 99.8)}%`,
            transition: "width 0.5s ease-in-out",
            height: "90%",
            backgroundColor: "#2faa7d",
          }}
        />
        <Typography
          component={"span"}
          sx={{
            fontWeight: "600",
            color: "#3D7C65",
            transform: "translateY(1.25rem)",
          }}
        >
          {currentPage}/{totalPages}
        </Typography>
      </Box>
    </Box>
  );
};

export default KLeadPage;
