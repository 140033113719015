import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import * as React from "react";

import i18next from "i18next";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import useCompetencyResponse from "../../hooks/useCompetencyResponse";
import useEmployeeFeedback from "../../hooks/useEmployeeFeedback";

const FeedbackForm = ({
  pageAnswers,
  setPageAnswers,
  setCompetencyAnsData,
}) => {
  const feedBackData = useSelector((state) => state.feedBackReducer);
  const { state } = useLocation();
  const lang = i18next.language;
  const { t } = useTranslation();
  const {
    competencyData,
    CompetencyError,
    refetchCompetency,
    isCompetencyLoading,
    isCompetencyFetching,
  } = useEmployeeFeedback({
    offset: feedBackData?.current_page,
    partner_id: state?.id,
    lang,
  });

  const {
    setCompetency,
    isCompetencyAnsLoading,
    competencyAnsError,
    competencyAnsSuccess,
    CompetencyAnsData,
  } = useCompetencyResponse();

  const handleSetAnswer = (e, item, description) => {
    setCompetency(
      competencyData?.records[0]?.id,
      item?.question_id,
      +e.target.value,
      state?.id,
      description
    );
    let payload = {
      competency_id: competencyData?.records[0]?.id,
      question_id: item?.question_id,
      answer_id: +e.target.value,
    };
    setPageAnswers({
      ...pageAnswers,
      [payload.question_id]: payload.answer_id,
    });
  };

  useEffect(() => {
    if (!!CompetencyAnsData) {
      setCompetencyAnsData((pre) => !pre);
    }
  }, [CompetencyAnsData]);

  const elements = [];
  if (competencyData?.records[0]?.question_and_page_ids) {
    const questionAndPageIds =
      competencyData?.records[0]?.question_and_page_ids;
    for (let i = 0; i < questionAndPageIds.length; i++) {
      const priorItem = questionAndPageIds[i - 1];
      const item = questionAndPageIds[i];
      const jsxElement = item?.title !== "Proficiency Level" && (
        <Box
          key={i}
          sx={{
            display: "flex",
            flexDirection: "column",
            marginTop: item?.is_page ? "10px" : "0px",
            marginBottom: item?.is_page ? "0px" : "10px",
            padding: item?.is_page
              ? "12px 18px 6px 18px"
              : "6px 18px 12px 18px",
            borderRadius: item?.is_page ? "8px 8px 0 0" : "0 0 8px 8px",
            backgroundColor: "#FFFFFFB2",
          }}
        >
          {item?.is_page ? (
            <Box
              key={item?.question_id}
              sx={{
                borderLeft: "5px solid #3D7C65",
                borderRadius: "5px",

                paddingLeft: "15px",
                marginBottom: "12px",
              }}
            >
              <Typography
                sx={{
                  color: "#3D7C65",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                {item?.title.includes("Element")
                  ? `${t(item.title.split(" ")[0])} ${item.title.split(" ")[1]}`
                  : item.title}
              </Typography>
            </Box>
          ) : (
            <RadioGroup
              key={item?.question_id}
              aria-labelledby="demo-radio-buttons-group-label"
              name="radio-buttons-group"
              defaultValue={item?.submitted_answer_details?.submitted_answer_id}
              onChange={(e) => {
                handleSetAnswer(e, item, priorItem?.title);
              }}
            >
              {item?.suggested_answer_ids?.map((que, j) => {
                return (
                  <Box key={j} sx={{ mb: 0.75 }}>
                    <FormControlLabel
                      key={j}
                      value={
                        que.answer_id?.toString() ||
                        item?.submitted_answer_details?.submitted_answer_id?.toString()
                      }
                      name={i.toString()} // Convert `i` to a string
                      style={{ display: "flex", alignItems: "center" }}
                      control={
                        <Radio sx={{ p: 0.8, color: "#3D7C65" }} size="small" />
                      }
                      label={
                        <Typography
                          sx={{
                            display: "flex",
                            color:
                              que.answer_id ===
                              item?.submitted_answer_details
                                ?.submitted_answer_id
                                ? "#3D7C65"
                                : "#15134B",
                            fontWeight: "400",
                            textAlign: "left",
                            marginLeft: "8px",
                          }}
                        >
                          {que.value}
                        </Typography>
                      }
                      disabled={isCompetencyAnsLoading}
                    />
                  </Box>
                );
              })}
            </RadioGroup>
          )}
        </Box>
      );
      elements.push(jsxElement);
    }
  }

  return <React.Fragment>{elements}</React.Fragment>;
};

export default FeedbackForm;
