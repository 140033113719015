import { Box, Grid } from "@mui/material";

import { useSelector } from "react-redux";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import DashboardSidebar from "../../components/DashboardSidebar";
import useEmployeeData from "../../hooks/useEmployeeData";
import useFeedback360Degree from "../../hooks/useFeedback360Degree";
import { setActiveKey } from "../../store/Product/action";
import EmployeeReview from "../employee-feedback/EmployeeReview";
import Header from "../header/Header";
import BpInstructions from "../instructions/BpInstructions";
import KleadInstructions from "../instructions/KleadInstructions";
import PLWiseInstructions from "../instructions/PLWiseInstructions";
import SjqInstructions from "../instructions/SjqInstructions";
import Guidelines from "./Guidelines";

const DashboardLayout = () => {
  const dispatch = useDispatch();

  const [employListShow, setEmployList] = useState(false);
  const [competencyTestShow, setCompetencyTest] = useState(false);

  const activeKey = useSelector((state) => state.productReducer.active_id);

  const { data: AllUsers } = useEmployeeData();

  const { success, noRecords, fetchCompetencies } = useFeedback360Degree();

  useEffect(() => {
    if (AllUsers?.records?.length === 0) {
      setEmployList(false);
    } else {
      setEmployList(true);
    }

    // iterate all records and check their job_level and put into a set. If the set has 1-4 then return true else false
    const jobLevels = new Set();
    AllUsers?.records?.forEach((user) => {
      jobLevels.add(user.job_level);
    });

    if (
      jobLevels.has("1") ||
      jobLevels.has("2") ||
      jobLevels.has("3") ||
      jobLevels.has("4")
    ) {
      sessionStorage.setItem("UBA_1_4_VISIBLE", "TRUE");
    } else {
      sessionStorage.setItem("UBA_1_4_VISIBLE", "FALSE");
    }

    if (
      jobLevels.has("5") ||
      jobLevels.has("6") ||
      jobLevels.has("7") ||
      jobLevels.has("8") ||
      jobLevels.has("9") ||
      jobLevels.has("10") ||
      jobLevels.has("11") ||
      jobLevels.has("12") ||
      jobLevels.has("13") ||
      jobLevels.has("14")
    ) {
      sessionStorage.setItem("UBA_5_14_VISIBLE", "TRUE");
    } else {
      sessionStorage.setItem("UBA_5_14_VISIBLE", "FALSE");
    }
  }, [AllUsers]);

  useEffect(() => {
    dispatch(setActiveKey(0));
  }, [dispatch]);

  useEffect(() => {
    fetchCompetencies(1);
  }, [fetchCompetencies]);

  useEffect(() => {
    if (success) {
      if (noRecords) {
        setCompetencyTest(false);
      } else {
        setCompetencyTest(true);
      }
    }
  }, [success, noRecords]);

  useEffect(() => {
    // Disable right-click
    const handleContextMenu = (event) => event.preventDefault();
    document.addEventListener("contextmenu", handleContextMenu);

    // Hide screen when window loses focus
    const handleWindowBlur = () => {
      document.body.classList.add("overlay");
    };

    // Show screen when window gains focus
    const handleWindowFocus = () => {
      document.body.classList.remove("overlay");
    };

    // Prevent common keyboard shortcuts
    const handleKeyDown = (event) => {
      if (
        event.key === "F12" || // F12
        (event.ctrlKey && event.shiftKey && event.key === "I") || // Ctrl+Shift+I
        (event.ctrlKey && event.shiftKey && event.key === "J") || // Ctrl+Shift+J
        (event.ctrlKey && event.shiftKey && event.key === "C") || // Ctrl+Shift+C
        (event.ctrlKey && event.key === "U") || // Ctrl+U
        (event.ctrlKey && event.shiftKey && event.key === "S") || // Ctrl+Shift+S
        (event.ctrlKey && event.shiftKey && event.key === "E") || // Ctrl+Shift+E
        (event.metaKey && event.altKey && event.key === "I") || // Cmd+Opt+I (Mac)
        (event.metaKey && event.altKey && event.key === "J") || // Cmd+Opt+J (Mac)
        (event.metaKey && event.altKey && event.key === "C") || // Cmd+Opt+C (Mac)
        (event.metaKey && event.altKey && event.key === "U") || // Cmd+Opt+U (Mac)
        (event.metaKey && event.shiftKey && event.key === "3") || // Cmd+Shift+3 (Mac Screenshot)
        (event.metaKey && event.shiftKey && event.key === "4") || // Cmd+Shift+4 (Mac Screenshot)
        (event.metaKey && event.shiftKey && event.key === "5") || // Cmd+Shift+5 (Mac Screenshot)
        (event.metaKey && event.shiftKey && event.key === "S") || // Cmd+Shift+S (Mac)
        (event.metaKey && event.shiftKey && event.key === "E") // Cmd+Shift+E (Mac)
      ) {
        event.preventDefault();
      }
    };

    window.addEventListener("blur", handleWindowBlur);
    window.addEventListener("focus", handleWindowFocus);
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("visibilitychange", () => {
      if (document.hidden) {
        handleWindowBlur();
      } else {
        handleWindowFocus();
      }
    });

    return () => {
      document.removeEventListener("contextmenu", handleContextMenu);
      window.removeEventListener("blur", handleWindowBlur);
      window.removeEventListener("focus", handleWindowFocus);
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("visibilitychange", () => {
        if (document.hidden) {
          handleWindowBlur();
        } else {
          handleWindowFocus();
        }
      });
    };
  }, []);

  return (
    <Box sx={{ height: "100svh", width: "100svw", overflow: "hidden" }}>
      <Grid container>
        <Grid item xs={3.2} lg={2.8} xl={2.6} xxl={2.4}>
          <DashboardSidebar
            employListShow={employListShow}
            competencyTestShow={competencyTestShow}
          />
        </Grid>
        <Grid item xs={8.8} lg={9.2} xl={9.4} xxl={9.6}>
          <Grid container>
            <>
              <Grid item xs={12}>
                <Header />
              </Grid>
              {(() => {
                switch (activeKey) {
                  case 0:
                    return <Guidelines />;
                  case 1:
                    return <EmployeeReview />;
                  case 2:
                    return <PLWiseInstructions />;
                  case 3:
                    return <SjqInstructions />;
                  case 4:
                    return <EmployeeReview />;
                  case 5:
                    return <EmployeeReview />;
                  case 6:
                    return <KleadInstructions />;
                  case 7:
                    return <BpInstructions />;
                  default:
                    return null;
                }
              })()}
            </>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardLayout;
