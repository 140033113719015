import i18next from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { STORAGEKEY } from "../api";
import api from "../api/api";
import { GET_USER_INFO } from "../api/url";

import i18n from "../i18n/i18n";

const useUserData = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);
  const PARTNER_ID = sessionStorage.getItem(STORAGEKEY.PARTNER_ID);

  const userData = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const payload = {
      lang: i18next.language,
      partner_id: PARTNER_ID,
    };

    try {
      const response = await api.postWithToken(`${GET_USER_INFO}`, payload);
      if (response?.data?.success) {
        setData(response?.data?.data);
        setSuccess(response?.data?.success);
      }
    } catch (error) {
      setData(null);
      setError(error);

      if (error?.response?.status === 403) {
        sessionStorage.clear();
        i18n.changeLanguage("vi_VN");
        sessionStorage.setItem("selectedLanguage", "vi_VN");
        navigate("/login", { replace: true });
      }
    } finally {
      setIsLoading(false);
    }
  }, [PARTNER_ID]);

  useEffect(() => {
    userData();
  }, [userData]);

  return { isLoading, error, success, data };
};

export default useUserData;
