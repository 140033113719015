import { Box, ListItemText, MenuItem, Typography } from "@mui/material";
import { ArrowLeft, Category } from "iconsax-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useEmployeeData from "../hooks/useEmployeeData";
import useUserData from "../hooks/useUserData";
import { setActiveKey } from "../store/Product/action";

function determineRole(job_level, job_code) {
  // Convert job_level to an integer
  const level = parseInt(job_level);

  // Check for job levels and cn_ctc_branch_ho conditions to determine the role
  if (level >= 1 && level <= 4) {
    return "JL1-4";
  } else if (level >= 5 && level <= 6) {
    if (job_code?.startsWith("HO")) {
      return "JL5-6-hod";
    } else {
      return "JL5-6-branch";
    }
  } else if (level >= 7 && level <= 8) {
    return "JL7-8";
  } else if (level >= 9 && level <= 12) {
    return "JL9-12";
  } else if (level >= 13 && level <= 14) {
    return "JL13-14";
  } else if (level === 99) {
    return "JL99";
  }
}

const fitsInUbaL1L4 = (jobLevel) => {
  return (
    jobLevel === "1" || jobLevel === "2" || jobLevel === "3" || jobLevel === "4"
  );
};

export const filterEmployees = (data, activeKey, user) => {
  let newData = data?.records;

  newData = newData?.filter(
    (item) =>
      !(activeKey === 4 || activeKey === 5) ||
      (activeKey === 4 && fitsInUbaL1L4(item?.job_level)) ||
      (activeKey === 5 && !fitsInUbaL1L4(item?.job_level))
  );

  newData = newData?.filter(
    (item) =>
      !(
        (item?.job_level === "5" || item?.job_level === "6") &&
        !item?.job_code.startsWith("HO")
      )
  );

  newData = newData?.filter(
    (item) =>
      !(
        activeKey === 1 &&
        user?.email === "lhvan.ho@vietcombank.com.vn" &&
        item?.email === "ducpm1.ho@vietcombank.com.vn"
      )
  );

  newData = newData?.filter(
    (item) =>
      !(
        (activeKey === 1 && item?.job_level === "99") ||
        (activeKey === 4 && item?.job_level === "99")
      )
  );

  const filterEmails = [
    "VUONGND.TQU@VIETCOMBANK.COM.VN",
    "MIENVT.HBI@VIETCOMBANK.COM.VN",
    "DUNGNT.DNO@VIETCOMBANK.COM.VN",
    "HUNGDV.DNO@VIETCOMBANK.COM.VN",
    "NHIBT.DNO@VIETCOMBANK.COM.VN",
    "HANHLH.YBA@VIETCOMBANK.COM.VN",
    "VIETVH.DQN@VIETCOMBANK.COM.VN",
    "LINHNTN.HOA@VIETCOMBANK.COM.VN",
    "HONGDT.HOA@VIETCOMBANK.COM.VN",
    "THINHNC.HOA@VIETCOMBANK.COM.VN",
    "DUYPP.HOA@VIETCOMBANK.COM.VN",
    "HIEUTC.BDL@VIETCOMBANK.COM.VN",
    "HUYHG.BDN@VIETCOMBANK.COM.VN",
    "HANHTB.BDN@VIETCOMBANK.COM.VN",
    "CUONGNT.DHP@VIETCOMBANK.COM.VN",
    "ANHDUCTUTE@GMAIL.COM",
    "NVH29011997@GMAIL.COM",
    "HUYNHND.DHN@VIETCOMBANK.COM.VN",
    "PHUONGLV.TBG@VIETCOMBANK.COM.VN",
    "VUNN.LTH@VIETCOMBANK.COM.VN",
    "KHOAND.LTH@VIETCOMBANK.COM.VN",
    "TRUONGNN.TLA@VIETCOMBANK.COM.VN",
    "THUANLV.HMO@VIETCOMBANK.COM.VN",
    "DUONGNH.NBN@VIETCOMBANK.COM.VN",
    "LONGLV.NTL@VIETCOMBANK.COM.VN",
    "HUNGPV.DDO@VIETCOMBANK.COM.VN",
    "TUANNV.THB@VIETCOMBANK.COM.VN",
  ];

  if (activeKey === 1) {
    newData = newData?.filter(
      (item) =>
        !filterEmails
          .map((email) => email.toLowerCase())
          .includes(item?.email?.toLowerCase())
    );
  }

  return { records: newData };
};

const DashboardSidebar = ({ employListShow, competencyTestShow }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const activeKey = useSelector((state) => state.productReducer.active_id);

  const { data } = useUserData();
  const { data: employees } = useEmployeeData();

  const [filteredEmployees, setFilteredEmployees] = useState({
    review: [],
    uba_l1_l4: [],
    uba_l5_l14: [],
  });

  const [mode, setMode] = useState("choose");
  const [role, setRole] = useState(null);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isCoreAndLeadershipCompleted = () => {
    const isSJQAccessible =
      role && role !== "JL1-4" && role !== "JL13-14" && role !== "JL99";
    const isUBAL1L4Accessible =
      role &&
      role !== "JL99" &&
      sessionStorage.getItem("UBA_1_4_VISIBLE") === "TRUE";
    const isUBAL5L14Accessible =
      role &&
      role !== "JL1-4" &&
      (sessionStorage.getItem("UBA_5_14_VISIBLE") === "TRUE" ||
        role === "JL99");
    const isKLeadAccessible = role && (role === "JL13-14" || role === "JL99");
    const isBPAccessible = role && role === "JL5-6-branch";

    return (
      (!isSJQAccessible || data?.sjq_feedback_state === "done") &&
      (!isUBAL1L4Accessible ||
        filteredEmployees.uba_l1_l4?.every(
          (row) => row?.uba_l1_l4_feedback_state === "done"
        )) &&
      (!isUBAL5L14Accessible ||
        filteredEmployees.uba_l5_l14?.every(
          (row) => row?.uba_l5_l14_feedback_state === "done"
        )) &&
      (!isKLeadAccessible || data?.k_lead_feedback_state === "done") &&
      (!isBPAccessible || data?.business_presentation_feedback_state === "done")
    );
  };

  const isProfessionalCompleted = () => {
    const isEmployeeReviewAccessible =
      role !== "JL99" && data?.email !== "ducpm1.ho@vietcombank.com.vn";
    const isAssignmentAccessible = competencyTestShow && role !== "JL1-4";

    return (
      (!isEmployeeReviewAccessible ||
        filteredEmployees?.review.every(
          (row) => row?.professional_feedback_state === "done"
        )) &&
      (!isAssignmentAccessible || data?.professional_feedback_state === "done")
    );
  };

  useEffect(() => {
    if (pathname === "/dashboard") {
      setMode("choose");
    } else if (pathname === "/dashboard/reviews") {
      setMode("reviews");
    } else if (pathname === "/dashboard/forms") {
      setMode("forms");
    }
  }, [pathname]);

  useEffect(() => {
    dispatch(setActiveKey(0));
  }, [mode]);

  useEffect(() => {
    if (employees) {
      setFilteredEmployees((prev) => ({
        ...prev,
        review: filterEmployees(employees, 1, data)?.records || [],
        uba_l1_l4: employees?.records || [],
        uba_l5_l14: employees?.records || [],
      }));
    }
  }, [activeKey, data, employees]);

  useEffect(() => {
    if (data) {
      setRole(determineRole(data.job_level, data.job_code));
    }
  }, [data]);

  return (
    <Box
      sx={{
        backgroundColor: "#3D7C65",
        height: "100vh",
        minHeight: "700px",
        padding: "20px",
      }}
    >
      <Box
        sx={{
          width: "100%",
          border: "0px solid #FFFFFFCC",
          borderWidth: "0px 0 0.1px 0",
          borderColor: "#FFFFFFCC",
          padding: "24px 6px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#FFF",
              fontWeight: "700",
              margin: "10px 0",
            }}
          >
            {t("HELLO")}, {data?.name || "Manager"} 👋
          </Typography>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            sx={{
              color: "#FFFFFFCC",
              fontWeight: "200",
              margin: "10px 0",
            }}
          >
            {t("WelcomeToVCB")}
          </Typography>
        </Box>
      </Box>
      <Box sx={{}}>
        <Typography
          sx={{
            color: "#FFF",
            fontWeight: "500",
            margin: "20px 0",
          }}
        ></Typography>
      </Box>

      {(mode === "forms" || mode === "reviews") && (
        <Box
          sx={{
            fontWeight: "500",
            margin: "20px 0",
            display: "flex",
            gap: "1rem",
            color: "#FFF",
            cursor: "pointer",
          }}
          onClick={() => navigate("/dashboard", { replace: true })}
        >
          <ArrowLeft />

          <Typography>
            {mode === "reviews"
              ? t("ProfessionalNav")
              : t("CoreAndLeadershipNav")}
          </Typography>
        </Box>
      )}

      {mode === "choose" ? (
        <>
          {role !== "JL99" &&
            data?.email !== "ducpm1.ho@vietcombank.com.vn" && (
              <MenuItem
                sx={{
                  borderRadius: "8px",
                  marginBottom: "10px",
                  color: "#fff",
                  ":hover": {
                    color: "#3D7C65",
                    backgroundColor: "#ffffffc8",
                  },
                }}
                onClick={() => {
                  navigate("/dashboard/reviews", { replace: true });
                }}
              >
                <ListItemText
                  primary={
                    <Box sx={{ display: "flex", alignItems: "flex-center" }}>
                      <Box
                        sx={{
                          objectFit: "fill",
                          borderRadius: "5px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          "&:hover": {
                            color: "#3D7C65",
                          },
                        }}
                      >
                        <Category size="24" />
                      </Box>
                      <Box sx={{ marginLeft: "1em", flexGrow: 1 }}>
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            alignItems: "center",
                            gap: "0.5em",
                          }}
                        >
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "600",
                              display: "inline-block",
                              textAlign: "left",
                            }}
                          >
                            {t("Professional")}
                          </Typography>
                          <Typography
                            sx={{
                              fontSize: "14px",
                              color: "black",
                              fontWeight: "bold",
                              padding: "0 0.25rem",
                              backgroundColor: isProfessionalCompleted()
                                ? "#CCFF99"
                                : "red",
                              borderRadius: "5px",
                              display: "inline-block",
                              whiteSpace: "nowrap",
                            }}
                          >
                            {isProfessionalCompleted()
                              ? t("IsCompleted")
                              : t("IsNotCompleted")}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  }
                  sx={{ maxWidth: "90%" }}
                />
              </MenuItem>
            )}
          <MenuItem
            sx={{
              borderRadius: "8px",
              marginBottom: "10px",
              color: "#fff",
              ":hover": {
                color: "#3D7C65",
                backgroundColor: "#ffffffc8",
              },
            }}
            onClick={() => {
              navigate("/dashboard/forms", { replace: true });
            }}
          >
            <ListItemText
              primary={
                <Box sx={{ display: "flex", alignItems: "flex-center" }}>
                  <Box
                    sx={{
                      objectFit: "fill",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      "&:hover": {
                        color: "#3D7C65",
                      },
                    }}
                  >
                    <Category size="24" />
                  </Box>
                  <Box sx={{ marginLeft: "1em", flexGrow: 1 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        alignItems: "center",
                        gap: "0.5em",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "600",
                          display: "inline-block",
                          textAlign: "left",
                        }}
                      >
                        {t("CoreAndLeadership")}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          color: "black",
                          fontWeight: "bold",
                          padding: "0 0.25rem",
                          backgroundColor: isCoreAndLeadershipCompleted()
                            ? "#CCFF99"
                            : "red",
                          borderRadius: "5px",
                          display: "inline-block",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {isCoreAndLeadershipCompleted()
                          ? t("IsCompleted")
                          : t("IsNotCompleted")}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              }
              sx={{ maxWidth: "90%" }}
            />
          </MenuItem>
        </>
      ) : (
        <Box>
          {mode === "reviews" && (
            <>
              {employListShow && role && (
                <MenuItem
                  sx={{
                    backgroundColor: activeKey === 1 ? "#fff" : "#3D7C65",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    ":hover": {
                      backgroundColor:
                        activeKey === 1 ? "#ffffffc8" : "#ffffff7a",
                    },
                  }}
                  onClick={() => {
                    dispatch(setActiveKey(1));
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "flex-center" }}>
                        <Box
                          sx={{
                            objectFit: "fill",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                              color: "#3D7C65",
                            },
                          }}
                        >
                          <Category
                            size="24"
                            color={activeKey === 1 ? "#3D7C65" : "#fff"}
                          />
                        </Box>
                        <Box sx={{ marginLeft: "1em", flexGrow: 1 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "0.5em",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: "600",
                                display: "inline-block",
                                textAlign: "left",
                                color: activeKey === 1 ? "#3D7C65" : "#fff",
                              }}
                            >
                              {t("EmployeeReview")}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                                padding: "0 0.25rem",
                                backgroundColor:
                                  filteredEmployees?.review.every(
                                    (row) =>
                                      row?.professional_feedback_state ===
                                      "done"
                                  )
                                    ? "#CCFF99"
                                    : "red",
                                borderRadius: "5px",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {filteredEmployees?.review.every(
                                (row) =>
                                  row?.professional_feedback_state === "done"
                              )
                                ? t("IsCompleted")
                                : t("IsNotCompleted")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </MenuItem>
              )}
              {competencyTestShow && role !== "JL1-4" && (
                <MenuItem
                  sx={{
                    backgroundColor: activeKey === 2 ? "#fff" : "#3D7C65",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    ":hover": {
                      backgroundColor:
                        activeKey === 2 ? "#ffffffc8" : "#ffffff7a",
                    },
                  }}
                  onClick={() => {
                    dispatch(setActiveKey(2));
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "flex-center" }}>
                        <Box
                          sx={{
                            objectFit: "fill",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                              color: "#3D7C65",
                            },
                          }}
                        >
                          <Category
                            size="24"
                            color={activeKey === 2 ? "#3D7C65" : "#fff"}
                          />
                        </Box>
                        <Box sx={{ marginLeft: "1em", flexGrow: 1 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "0.5em",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: "600",
                                display: "inline-block",
                                textAlign: "left",
                                color: activeKey === 2 ? "#3D7C65" : "#fff",
                              }}
                            >
                              {t("Assignment")}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                                padding: "0 0.25rem",
                                backgroundColor:
                                  data?.professional_feedback_state === "done"
                                    ? "#CCFF99"
                                    : "red",
                                borderRadius: "5px",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {data?.professional_feedback_state === "done"
                                ? t("IsCompleted")
                                : t("IsNotCompleted")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </MenuItem>
              )}
            </>
          )}
          {mode === "forms" && (
            <>
              {role &&
                role !== "JL1-4" &&
                role !== "JL13-14" &&
                role !== "JL99" && (
                  <MenuItem
                    sx={{
                      backgroundColor: activeKey === 3 ? "#fff" : "#3D7C65",
                      borderRadius: "8px",
                      marginBottom: "10px",
                      ":hover": {
                        backgroundColor:
                          activeKey === 3 ? "#ffffffc8" : "#ffffff7a",
                      },
                    }}
                    onClick={() => {
                      dispatch(setActiveKey(3));
                    }}
                  >
                    <ListItemText
                      primary={
                        <Box
                          sx={{ display: "flex", alignItems: "flex-center" }}
                        >
                          <Box
                            sx={{
                              objectFit: "fill",
                              borderRadius: "5px",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              "&:hover": {
                                color: "#3D7C65",
                              },
                            }}
                          >
                            <Category
                              size="24"
                              color={activeKey === 3 ? "#3D7C65" : "#fff"}
                            />
                          </Box>
                          <Box sx={{ marginLeft: "1em", flexGrow: 1 }}>
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                alignItems: "center",
                                gap: "0.5em",
                              }}
                            >
                              <Typography
                                variant="subtitle1"
                                sx={{
                                  fontWeight: "600",
                                  display: "inline-block",
                                  textAlign: "left",
                                  fontSize: "80%",
                                  color: activeKey === 3 ? "#3D7C65" : "#fff",
                                }}
                              >
                                {t("SJQ")}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "14px",
                                  color: "black",
                                  fontWeight: "bold",
                                  padding: "0 0.25rem",
                                  backgroundColor:
                                    data?.sjq_feedback_state === "done"
                                      ? "#CCFF99"
                                      : "red",
                                  borderRadius: "5px",
                                  display: "inline-block",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {data?.sjq_feedback_state === "done"
                                  ? t("IsCompleted")
                                  : t("IsNotCompleted")}
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      }
                      sx={{ maxWidth: "90%" }}
                    />
                  </MenuItem>
                )}
              {employListShow && (
                <span>
                  {role &&
                    role !== "JL99" &&
                    sessionStorage.getItem("UBA_1_4_VISIBLE") === "TRUE" && (
                      <MenuItem
                        sx={{
                          backgroundColor: activeKey === 4 ? "#fff" : "#3D7C65",
                          borderRadius: "8px",
                          marginBottom: "10px",
                          ":hover": {
                            backgroundColor:
                              activeKey === 4 ? "#ffffffc8" : "#ffffff7a",
                          },
                        }}
                        onClick={() => {
                          dispatch(setActiveKey(4));
                        }}
                      >
                        <ListItemText
                          primary={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-center",
                              }}
                            >
                              <Box
                                sx={{
                                  objectFit: "fill",
                                  borderRadius: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  "&:hover": {
                                    color: "#3D7C65",
                                  },
                                }}
                              >
                                <Category
                                  size="24"
                                  color={activeKey === 4 ? "#3D7C65" : "#fff"}
                                />
                              </Box>
                              <Box sx={{ marginLeft: "1em", flexGrow: 1 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    gap: "0.5em",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontWeight: "600",
                                      display: "inline-block",
                                      textAlign: "left",
                                      color:
                                        activeKey === 4 ? "#3D7C65" : "#fff",
                                    }}
                                  >
                                    {t("UBA")}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "black",
                                      fontWeight: "bold",
                                      padding: "0 0.25rem",
                                      backgroundColor:
                                        filteredEmployees?.uba_l1_l4?.every(
                                          (row) =>
                                            row?.uba_l1_l4_feedback_state ===
                                            "done"
                                        )
                                          ? "#CCFF99"
                                          : "red",
                                      borderRadius: "5px",
                                      display: "inline-block",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {filteredEmployees?.uba_l1_l4?.every(
                                      (row) =>
                                        row?.uba_l1_l4_feedback_state === "done"
                                    )
                                      ? t("IsCompleted")
                                      : t("IsNotCompleted")}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          }
                          sx={{ maxWidth: "90%" }}
                        />
                      </MenuItem>
                    )}
                  {role &&
                    role !== "JL1-4" &&
                    (sessionStorage.getItem("UBA_5_14_VISIBLE") === "TRUE" ||
                      role === "JL99") && (
                      <MenuItem
                        sx={{
                          backgroundColor: activeKey === 5 ? "#fff" : "#3D7C65",
                          borderRadius: "8px",
                          marginBottom: "10px",
                          ":hover": {
                            backgroundColor:
                              activeKey === 5 ? "#ffffffc8" : "#ffffff7a",
                          },
                        }}
                        onClick={() => {
                          dispatch(setActiveKey(5));
                        }}
                      >
                        <ListItemText
                          primary={
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-center",
                              }}
                            >
                              <Box
                                sx={{
                                  objectFit: "fill",
                                  borderRadius: "5px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  "&:hover": {
                                    color: "#3D7C65",
                                  },
                                }}
                              >
                                <Category
                                  size="24"
                                  color={activeKey === 5 ? "#3D7C65" : "#fff"}
                                />
                              </Box>
                              <Box sx={{ marginLeft: "1em", flexGrow: 1 }}>
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",
                                    alignItems: "center",
                                    gap: "0.5em",
                                  }}
                                >
                                  <Typography
                                    variant="subtitle1"
                                    sx={{
                                      fontWeight: "600",
                                      display: "inline-block",
                                      textAlign: "left",
                                      color:
                                        activeKey === 5 ? "#3D7C65" : "#fff",
                                    }}
                                  >
                                    {t("UBA")}
                                  </Typography>
                                  <Typography
                                    sx={{
                                      fontSize: "14px",
                                      color: "black",
                                      fontWeight: "bold",
                                      padding: "0 0.25rem",
                                      backgroundColor:
                                        filteredEmployees?.uba_l5_l14?.every(
                                          (row) =>
                                            row?.uba_l5_l14_feedback_state ===
                                            "done"
                                        )
                                          ? "#CCFF99"
                                          : "red",
                                      borderRadius: "5px",
                                      display: "inline-block",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    {filteredEmployees?.uba_l5_l14?.every(
                                      (row) =>
                                        row?.uba_l5_l14_feedback_state ===
                                        "done"
                                    )
                                      ? t("IsCompleted")
                                      : t("IsNotCompleted")}
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          }
                          sx={{ maxWidth: "90%" }}
                        />
                      </MenuItem>
                    )}
                </span>
              )}
              {role && (role === "JL13-14" || role === "JL99") && (
                <MenuItem
                  sx={{
                    backgroundColor: activeKey === 6 ? "#fff" : "#3D7C65",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    ":hover": {
                      backgroundColor:
                        activeKey === 6 ? "#ffffffc8" : "#ffffff7a",
                    },
                  }}
                  onClick={() => {
                    dispatch(setActiveKey(6));
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "flex-center" }}>
                        <Box
                          sx={{
                            objectFit: "fill",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                              color: "#3D7C65",
                            },
                          }}
                        >
                          <Category
                            size="24"
                            color={activeKey === 6 ? "#3D7C65" : "#fff"}
                          />
                        </Box>
                        <Box sx={{ marginLeft: "1em", flexGrow: 1 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "0.5em",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: "600",
                                display: "inline-block",
                                textAlign: "left",
                                color: activeKey === 6 ? "#3D7C65" : "#fff",
                              }}
                            >
                              {t("K-Lead")}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                                padding: "0 0.25rem",
                                backgroundColor:
                                  data?.k_lead_feedback_state === "done"
                                    ? "#CCFF99"
                                    : "red",
                                borderRadius: "5px",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {data?.k_lead_feedback_state === "done"
                                ? t("IsCompleted")
                                : t("IsNotCompleted")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </MenuItem>
              )}
              {role && role === "JL5-6-branch" && (
                <MenuItem
                  sx={{
                    backgroundColor: activeKey === 7 ? "#fff" : "#3D7C65",
                    borderRadius: "8px",
                    marginBottom: "10px",
                    ":hover": {
                      backgroundColor:
                        activeKey === 7 ? "#ffffffc8" : "#ffffff7a",
                    },
                  }}
                  onClick={() => {
                    dispatch(setActiveKey(1));
                  }}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: "flex", alignItems: "flex-center" }}>
                        <Box
                          sx={{
                            objectFit: "fill",
                            borderRadius: "5px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            "&:hover": {
                              color: "#3D7C65",
                            },
                          }}
                        >
                          <Category
                            size="24"
                            color={activeKey === 7 ? "#3D7C65" : "#fff"}
                          />
                        </Box>
                        <Box sx={{ marginLeft: "1em", flexGrow: 1 }}>
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              alignItems: "center",
                              gap: "0.5em",
                            }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                fontWeight: "600",
                                display: "inline-block",
                                textAlign: "left",
                                color: activeKey === 7 ? "#3D7C65" : "#fff",
                              }}
                            >
                              {t("BP")}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "14px",
                                color: "black",
                                fontWeight: "bold",
                                padding: "0 0.25rem",
                                backgroundColor:
                                  data?.business_presentation_feedback_state ===
                                  "done"
                                    ? "#CCFF99"
                                    : "red",
                                borderRadius: "5px",
                                display: "inline-block",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {data?.business_presentation_feedback_state ===
                              "done"
                                ? t("IsCompleted")
                                : t("IsNotCompleted")}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    }
                    sx={{ maxWidth: "90%" }}
                  />
                </MenuItem>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default DashboardSidebar;
