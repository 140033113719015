import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const UbaGlossaryContent = ({ isPartOne: isOne, isModal }) => {
  const { t } = useTranslation();

  const [isPartOne, setIsPartOne] = useState(isOne);

  const renderBulletPoints = (titleKey) => {
    const bulletPoints = [];
    for (let i = 1; ; i++) {
      const bulletPoint = t(`${titleKey}${i}`);
      if (bulletPoint === `${titleKey}${i}`) break;
      bulletPoints.push(
        <Typography
          key={`${titleKey}${i}`}
          sx={{
            display: "flex",
            color: "#15134B",
            fontWeight: "400",
            textAlign: "left",
            marginLeft: "8px",
            marginBottom: "4px",
          }}
        >
          <span
            style={{
              fontWeight: "600",
              marginRight: "8px",
            }}
          >
            &#x2022;
          </span>{" "}
          {bulletPoint}
        </Typography>
      );
    }
    return bulletPoints;
  };

  const renderTitles = () => {
    const titles = [];
    const titleKeys = isPartOne
      ? ["P1Novice", "P1Practitioner", "P1Proficient", "P1Expert", "P1Champion"]
      : ["P2Execution", "P2Application", "P2Advanced", "P2Coach", "P2Mastery"];
    titleKeys.forEach((titleKey, index) => {
      titles.push(
        <Box
          key={titleKey}
          sx={{
            borderLeft: "5px solid #3D7C65",
            borderRadius: "5px",
            paddingLeft: "15px",
            marginTop: "10px",
            marginBottom: "8px",
          }}
        >
          <Typography
            sx={{
              color: "#3D7C65",
              fontWeight: "600",
              textAlign: "left",
            }}
          >
            {t(titleKey)} (
            {
              t(
                `PL${index + 1}${
                  index === 0
                    ? "Basic"
                    : index === 1
                    ? "Inter"
                    : index === 2
                    ? "Profound"
                    : index === 3
                    ? "Expert"
                    : "Mastery"
                }`
              ).split(" (")[0]
            }
            )
          </Typography>
        </Box>
      );
      titles.push(...renderBulletPoints(titleKey));
    });
    return titles;
  };

  useEffect(() => {
    sessionStorage.setItem("UBA_GLOSSARY_PART", isPartOne ? "1" : "2");
    const isUBA5_14 = !!sessionStorage.getItem("UBA_L5_L14_CURRENT_PAGE");

    if (isUBA5_14) {
      sessionStorage.setItem("UBA_GLOSSARY_PART", "1");
      setIsPartOne(true);
    }

    return () => {
      sessionStorage.removeItem("UBA_GLOSSARY_PART");
    };
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        flex: 1,
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        padding: "1rem 1rem 4rem 1rem",
        overflowY: "auto",
        borderRadius: "8px",
        backgroundColor: "#FFFFFFB2",
      }}
    >
      {isModal ? null : (
        <Typography
          sx={{
            color: "#3D7C65",
            fontWeight: "600",
            mb: 2,
          }}
        >
          {t("BelowDefProfLavel")}
        </Typography>
      )}
      <Typography
        sx={{
          color: "#3D7C65",
          fontWeight: "600",
          mb: 2,
        }}
      >
        {t("UBAHeaderDescription")}
      </Typography>

      {renderTitles()}
    </Box>
  );
};

export default UbaGlossaryContent;
