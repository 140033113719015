import React, { useEffect, useState } from "react";

import {
  Alert,
  Box,
  FormControlLabel,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";

import InstructionsBack from "../assets/InstructionsBack.png";

import NoDataFound from "../assets/NoDataFound.png";
import BPPaginationFooter from "../components/business-presentation/BPPaginationFooter";
import useBusinessPresentation from "../hooks/useBusinessPresentation";

const BusinessPresentation = () => {
  const {
    t,
    isLoading,
    error,
    alert,
    setAlert,
    fetchBpQuestions,
    questions,
    currentPage,
    totalPages,
    handlePageChange,
    success,
    handleBpAnswer,
    isBpFinished,
    submissionModalState,
    setSubmissionModalState,
  } = useBusinessPresentation();

  const [open, setOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleOpen = (imageSrc) => {
    setSelectedImage(imageSrc);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedImage(null);
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        handleClose();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  return (
    <Box
      sx={{
        backgroundImage: `url(${InstructionsBack})`,
        height: "78svh",
        width: "100%",
        padding: "30px",
        overflowY: "hidden",
        position: "relative",
      }}
    >
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-viewer"
        aria-describedby="image-viewer-description"
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            outline: "none",
          }}
          onClick={handleClose}
        >
          <img
            src={selectedImage}
            alt="attachment"
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              objectFit: "contain",
            }}
          />
        </Box>
      </Modal>
      <Box
        element="main"
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-between"}
        height={"100%"}
      >
        <Box>
          {isLoading ? null : error ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <img src={NoDataFound} alt="No Data Found" />
              <Typography variant="h6">{t("NoDataFound")}</Typography>
            </Box>
          ) : (
            <>
              {questions[currentPage - 1] && (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "1.1fr 0.9fr",
                    width: "100%",
                    gap: "1rem",
                  }}
                >
                  <Box
                    sx={{
                      position: "relative",
                      borderRadius: "8px",
                      padding: "0.5rem 1rem",
                      backgroundColor: "#3D7C65",
                      color: "white",
                      height: "72svh",
                      overflowY: "auto",
                    }}
                  >
                    <div
                      id="bp-question"
                      key={questions[currentPage - 1].question_id}
                      dangerouslySetInnerHTML={{
                        __html: questions[currentPage - 1].title,
                      }}
                      style={{
                        fontSize: "90%",
                        lineHeight: "1.5",
                      }}
                    />

                    {questions[currentPage - 1]?.attachments &&
                      questions[currentPage - 1]?.attachments.map(
                        (attachment, index) => {
                          const src = `data:${attachment.mimetype};base64,${attachment.datas}`;

                          if (
                            questions[currentPage - 1].name ===
                            "Tư duy chiến lược và định hướng kinh doanh 1"
                          ) {
                            return (
                              <img
                                key={index}
                                src={"/bp-page3.png"}
                                alt="attachment"
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleOpen("/bp-page3.png")}
                              />
                            );
                          }

                          if (
                            questions[currentPage - 1].name ===
                            "Tư duy kỹ thuật số 1"
                          ) {
                            return (
                              <img
                                key={index}
                                src={"/bp-page5.png"}
                                alt="attachment"
                                style={{
                                  width: "100%",
                                  objectFit: "contain",
                                  cursor: "pointer",
                                }}
                                onClick={() => handleOpen("/bp-page5.png")}
                              />
                            );
                          }

                          if (
                            questions[currentPage - 1].name ===
                            "Định hướng quy trình 2"
                          ) {
                            if (index === 0) {
                              return (
                                <img
                                  key={index}
                                  src={"/bp-page10-1.png"}
                                  alt="attachment"
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleOpen("/bp-page10-1.png")}
                                />
                              );
                            } else if (index === 1) {
                              return (
                                <img
                                  key={index}
                                  src={"/bp-page10-2.png"}
                                  alt="attachment"
                                  style={{
                                    width: "100%",
                                    objectFit: "contain",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => handleOpen("/bp-page10-2.png")}
                                />
                              );
                            }
                          }

                          return (
                            <img
                              key={index}
                              src={src}
                              alt="attachment"
                              style={{
                                width: "100%",
                                objectFit: "contain",
                                cursor: "pointer",
                              }}
                              onClick={() => handleOpen(src)}
                            />
                          );
                        }
                      )}

                    {(questions[currentPage - 1].name ===
                      "Innovation and Change 1" ||
                      questions[currentPage - 1].name ===
                        "Sáng tạo và chuyển đổi") && (
                      <table
                        style={{
                          fontSize: "80%",
                          backgroundColor: "white",
                          color: "black",
                          padding: "0.25rem",
                          margin: "0.5rem 0",
                        }}
                      >
                        <thead
                          style={{
                            background: "#3D7C65",
                            color: "white",
                          }}
                        >
                          <tr>
                            <th>{t("Table2Header1")}</th>
                            <th>{t("Table2Header2")}</th>
                            <th>{t("Table2Header3")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("Table2Parameter1")}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#dde184",
                                textAlign: "center",
                              }}
                            >
                              4
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fbb078",
                                textAlign: "center",
                              }}
                            >
                              8
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("Table2Parameter2")}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#b5d681",
                                textAlign: "center",
                              }}
                            >
                              3
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fde583",
                                textAlign: "center",
                              }}
                            >
                              5
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("Table2Parameter3")}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fde583",
                                textAlign: "center",
                              }}
                            >
                              2
                            </td>
                            <td
                              style={{
                                backgroundColor: "#f3e783",
                                textAlign: "center",
                              }}
                            >
                              4.5
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("Table2Parameter4")}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#61bd7b",
                                textAlign: "center",
                              }}
                            >
                              1
                            </td>
                            <td
                              style={{
                                backgroundColor: "#8bc97e",
                                textAlign: "center",
                              }}
                            >
                              2
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("Table2Parameter5")}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fcd481",
                                textAlign: "center",
                              }}
                            >
                              6
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fbb078",
                                textAlign: "center",
                              }}
                            >
                              8
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("Table2Parameter6")}
                            </td>
                            <td
                              style={{
                                backgroundColor: "#fee684",
                                textAlign: "center",
                              }}
                            >
                              5
                            </td>
                            <td
                              style={{
                                backgroundColor: "#f56867",
                                textAlign: "center",
                              }}
                            >
                              12
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}

                    {(questions[currentPage - 1].name ===
                      "Innovation and Change 2" ||
                      questions[currentPage - 1].name ===
                        "Sáng tạo và chuyển đổi 2") && (
                      <table
                        style={{
                          fontSize: "80%",
                          backgroundColor: "white",
                          color: "black",
                          padding: "0.25rem",
                          margin: "0.5rem 0",
                        }}
                      >
                        <thead
                          style={{
                            textAlign: "left",
                            background: "#3D7C65",
                            color: "white",
                          }}
                        >
                          <tr>
                            <th>{t("TableHeader1")}</th>
                            <th>{t("TableHeader2")}</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableParameter1")}
                            </td>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableValue1")}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableParameter2")}
                            </td>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableValue2")}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableParameter3")}
                            </td>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableValue3")}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableParameter4")}
                            </td>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableValue4")}
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableParameter5")}
                            </td>
                            <td
                              style={{
                                border: "2px solid #3D7C65",
                              }}
                            >
                              {t("TableValue5")}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    )}
                  </Box>

                  <Box
                    sx={{
                      height: "72svh",
                      overflowY: "auto",
                    }}
                  >
                    <RadioGroup
                      aria-label="answer"
                      name="answer"
                      value={
                        questions[currentPage - 1]?.submitted_answer_details
                          ?.submitted_answer_id || ""
                      }
                      onChange={(e) =>
                        handleBpAnswer({
                          competency_id:
                            questions[currentPage - 1].competency_id,
                          question_id: questions[currentPage - 1].question_id,
                          answer_id: parseInt(e.target.value),
                          description: "",
                        })
                      }
                      sx={{ gap: "0.5rem" }}
                    >
                      {questions[currentPage - 1].values.map((item) => (
                        <Box
                          key={`answer-${item.answer_id}`}
                          sx={{
                            backgroundColor: "#FFFFFFB2",
                            padding: "0.5rem 1rem",
                            borderRadius: "8px",
                          }}
                        >
                          <FormControlLabel
                            value={item.answer_id}
                            control={
                              <Radio
                                checked={
                                  item.answer_id ===
                                  questions[currentPage - 1]
                                    ?.submitted_answer_details
                                    ?.submitted_answer_id
                                }
                                sx={{ p: 0.8, color: "#3D7C65" }}
                                size="small"
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  display: "flex",
                                  color:
                                    item.answer_id ===
                                    questions[currentPage - 1]
                                      ?.submitted_answer_details
                                      ?.submitted_answer_id
                                      ? "#3D7C65"
                                      : "#15134B",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  marginLeft: "8px",
                                }}
                              >
                                {item.value}
                              </Typography>
                            }
                          />
                        </Box>
                      ))}
                    </RadioGroup>
                  </Box>
                </Box>
              )}
            </>
          )}
        </Box>
      </Box>

      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={() => setAlert({ open: false, message: "" })}
        sx={{ textAlign: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlert({ open: false, message: "" })}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      {
        <BPPaginationFooter
          prevLabel={t("Prev")}
          nextLabel={t("Next")}
          submitLabel={t("Submit")}
          currentPage={parseInt(currentPage)}
          totalPage={parseInt(totalPages)}
          isAssessmentFinished={isBpFinished}
          onPreviousClick={() => handlePageChange("prev")}
          onNextClick={() => handlePageChange("next")}
          submissionModalState={submissionModalState}
          setSubmissionModalState={setSubmissionModalState}
        />
      }
    </Box>
  );
};

export default BusinessPresentation;
