import i18next from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import api from "../api/api";
import {
  GET_UBA_COMPETENCY,
  SET_COMPETENCY_ANS,
  SET_PROFICIENCY_ANS,
} from "../api/url";

const useUBAForm = ({ type }) => {
  const { t } = useTranslation();
  const competencyType = type === "uba_l1_l4" ? "uba_l1_l4" : "uba_l5_l14";
  const lang = i18next.language;

  const [isFormCompleted, setIsFormCompleted] = useState(false);
  const [ubaQuestions, setUbaQuestions] = useState({});

  const [submissionModalState, setSubmissionModalState] = useState({
    open: false,
    message: t("AssessmentPaused"),
  });

  const [isPartTwoAssessible, setIsPartTwoAssessible] = useState({});
  const [isNextPageAssessible, setIsNextPageAssessible] = useState({});

  const [currentPage, setCurrentPage] = useState(0);
  const [currentPart, setCurrentPart] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState({ open: false, message: "" });
  const [success, setSuccess] = useState(false);

  const { state } = useLocation();

  const partner_id = state?.id;

  const fetchUBAFormData = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.postWithToken(GET_UBA_COMPETENCY, {
        lang,
        partner_id: +partner_id,
        competency_type: competencyType,
      });

      if (response?.data?.success) {
        const records = response.data.data.records || [];

        const max = records.length;
        let activePage = 1;
        let pagesData = {};

        records.forEach((record, index) => {
          const pageData = {
            competencyId: record.id,
            name: record.name,
            definition: record.competency_definition,
            partOneQuestions: record.question_and_page_ids
              .filter((question) => question.title !== "Proficiency Level")
              .reduce((acc, question, index, arr) => {
                if (question.title.startsWith("Element") && arr[index + 1]) {
                  acc.push({
                    header: question.title,
                    question_id: arr[index + 1].question_id,
                    submitted_answer_details:
                      arr[index + 1].submitted_answer_details,
                    values: arr[index + 1].suggested_answer_ids.map(
                      (answer) => ({
                        ...answer,
                      })
                    ),
                  });
                }
                return acc;
              }, []),
            partTwoQuestions: record.question_and_page_ids
              .filter((question) => question.title === "Proficiency Level")
              .map((question) => ({
                title: question.title,
                question_id: question.question_id,
                values: question.suggested_answer_ids,
                submitted_answer_details: question.submitted_answer_details,
              })),
          };

          pagesData[index + 1] = pageData;

          // check for part 1 submitted answers and make part 2 assessible
          const partOneFilled = pageData.partOneQuestions.every(
            (question) =>
              question.submitted_answer_details &&
              question.submitted_answer_details.submitted_answer
          );

          const partTwoFilled =
            pageData.partTwoQuestions[0].submitted_answer_details
              .submitted_answer;

          setIsPartTwoAssessible((prev) => ({
            ...prev,
            [index + 1]: partOneFilled,
          }));

          setIsNextPageAssessible((prev) => ({
            ...prev,
            [index + 1]: partTwoFilled,
          }));

          if (partTwoFilled) {
            activePage = Math.min(index + 2, max);
            if (activePage === max) {
              setIsFormCompleted(true);
            }
          }
        });

        setUbaQuestions(pagesData);
        setTotalPages(max);

        const kLeadCurrentPage = sessionStorage.getItem(
          type === "uba_l1_l4"
            ? "UBA_L1_L4_CURRENT_PAGE"
            : "UBA_L5_L14_CURRENT_PAGE"
        );

        if (kLeadCurrentPage) {
          activePage = +kLeadCurrentPage;
        }

        setCurrentPage(activePage);
        setSuccess(true);
      } else {
        setSuccess(false);
      }
    } catch (error) {
      setError(error.message);
      setSuccess(false);
    } finally {
      setIsLoading(false);
    }
  }, [lang, partner_id, competencyType]);

  useEffect(() => {
    fetchUBAFormData();
  }, [fetchUBAFormData]);

  const handleCurrentPartChange = useCallback(
    (part) => {
      if (part === 1 && isPartTwoAssessible[currentPage]) {
        setCurrentPart(1);
      } else if (part === 0) {
        setCurrentPart(0);
      } else {
        return setAlert({
          open: true,
          message: t("AssessmentPageIncomplete"),
          type: "warning",
        });
      }
    },
    [currentPage, isPartTwoAssessible, t]
  );

  const handlePageChange = useCallback(
    (action) => {
      if (action === "prev" && currentPage > 1) {
        if (currentPart === 1 && isPartTwoAssessible[currentPage]) {
          return setCurrentPart(0);
        }
        setCurrentPage((prevPage) => prevPage - 1);
        sessionStorage.setItem(
          type === "uba_l1_l4"
            ? "UBA_L1_L4_CURRENT_PAGE"
            : "UBA_L5_L14_CURRENT_PAGE",
          currentPage - 1
        );
      } else if (
        action === "next" &&
        currentPage < totalPages &&
        isNextPageAssessible[currentPage]
      ) {
        // if (!isPartTwoAssessible[currentPage + 1]) {
        // }
        setCurrentPart(0);
        setCurrentPage((prevPage) => prevPage + 1);
        sessionStorage.setItem(
          type === "uba_l1_l4"
            ? "UBA_L1_L4_CURRENT_PAGE"
            : "UBA_L5_L14_CURRENT_PAGE",
          currentPage + 1
        );
      } else {
        return setAlert({
          open: true,
          message: t("AssessmentPageIncomplete"),
          type: "warning",
        });
      }
    },
    [
      currentPage,
      totalPages,
      t,
      isNextPageAssessible,
      isPartTwoAssessible,
      currentPart,
    ]
  );

  const [requestInProgress, setRequestInProgress] = useState(false);

  const setUbaCompetency = async (
    competency_id,
    question_id,
    answer_id,
    description
  ) => {
    if (requestInProgress) return;

    setRequestInProgress(true);
    try {
      const response = await api.postWithToken(SET_COMPETENCY_ANS, {
        competency_id,
        question_id,
        answer_id,
        partner_id: +partner_id,
        description,
      });

      if (response?.data?.success) {
        setTimeout(() => {
          setRequestInProgress(false);
        }, 500);
        setUbaQuestions((prev) => {
          const updatedQuestions = { ...prev };
          const currentPageData = updatedQuestions[currentPage];
          const updatedPartOneQuestions = currentPageData.partOneQuestions.map(
            (question, index) => {
              if (question.question_id === question_id) {
                return {
                  ...question,
                  submitted_answer_details: {
                    submitted_answer: true,
                    submitted_answer_id: answer_id,
                  },
                };
              }
              return question;
            }
          );

          updatedQuestions[currentPage] = {
            ...currentPageData,
            partOneQuestions: updatedPartOneQuestions,
          };

          setIsPartTwoAssessible((prev) => {
            // has to be boolean
            const updated = { ...prev };
            updated[currentPage] = updatedPartOneQuestions.every(
              (question) => question.submitted_answer_details.submitted_answer
            );
            return updated;
          });

          return updatedQuestions;
        });
      }
    } catch (error) {
      setSuccess(false);
      setRequestInProgress(false);
      console.log("failed in competency submission", error);
    }
  };

  const setUbaProficiency = async (
    competency_id,
    question_id,
    answer_score
  ) => {
    try {
      if (requestInProgress) return;
      setRequestInProgress(true);

      const response = await api.postWithToken(`${SET_PROFICIENCY_ANS}`, {
        competency_id,
        question_id,
        answer_score,
        partner_id,
      });

      if (response?.data?.success) {
        setUbaQuestions((prev) => {
          const updatedQuestions = { ...prev };
          const currentPageData = updatedQuestions[currentPage];

          const updatedPartTwoQuestion = {
            ...currentPageData.partTwoQuestions[0],
            submitted_answer_details: {
              submitted_answer: true,
              submitted_answer_id: false,
              score: answer_score,
            },
          };

          updatedQuestions[currentPage] = {
            ...currentPageData,
            partTwoQuestions: [updatedPartTwoQuestion],
          };

          return updatedQuestions;
        });

        setIsNextPageAssessible((prev) => ({ ...prev, [currentPage]: true }));
        if (currentPage === totalPages) {
          setIsFormCompleted(true);
        }

        // Set a timeout of 5 seconds before allowing another request
        setTimeout(() => {
          setRequestInProgress(false);
        }, 500);
      } else {
        setRequestInProgress(false);
        setSuccess(false);
        setAlert({ open: true, message: t("FailedToSaveData"), type: "error" });
      }
    } catch (error) {
      setRequestInProgress(false);
      console.log("failed in proficiency submission", error);
    }
  };

  useEffect(() => {
    return () => {
      sessionStorage.removeItem(
        type === "uba_l1_l4"
          ? "UBA_L1_L4_CURRENT_PAGE"
          : "UBA_L5_L14_CURRENT_PAGE"
      );
    };
  }, []);

  return {
    ubaQuestions,
    currentPage,
    totalPages,
    isLoading,
    error,
    alert,
    setAlert,
    success,
    handlePageChange,
    currentPart,
    setCurrentPage,
    handleCurrentPartChange,
    setUbaCompetency,
    setUbaProficiency,
    isFormCompleted,
    isNextPageAssessible,
    submissionModalState,
    isPartTwoAssessible,
    setSubmissionModalState,
  };
};

export default useUBAForm;
