import React from "react";

import {
  Alert,
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Snackbar,
  Typography,
} from "@mui/material";

import InstructionsBack from "../assets/InstructionsBack.png";

import useSJQ from "../hooks/useSJQ";

import NoDataFound from "../assets/NoDataFound.png";
import SJQPaginationFooter from "../components/sjq/SJQPaginationFooter";
import SJQPageSidebar from "../components/sjq/SJQSidebar";

const SJQ = () => {
  const {
    t,
    isLoading,
    error,
    alert,
    setAlert,
    questions,
    currentPage,
    totalPages,
    handlePageChange,
    success,
    handleSjqAnswer,
    isSjqFinished,
    submissionModalState,
    setSubmissionModalState,
  } = useSJQ();

  return (
    <Box
      sx={{
        backgroundImage: `url(${InstructionsBack})`,
        height: "78svh",
        width: "100%",
        padding: "30px",
        overflowY: "hidden",
        position: "relative",
      }}
    >
      <Box
        element="section"
        display={"flex"}
        flexDirection={"row"}
        justifyContent={"space-between"}
        gap={"1rem"}
        sx={{
          height: "100%",
        }}
      >
        <SJQPageSidebar />

        <Box
          element="main"
          display={"flex"}
          flexDirection={"column"}
          width={"75%"}
          justifyContent={"space-between"}
        >
          <Box
            sx={{
              overflow: "auto",
              height: "100%",
              width: "100%",
            }}
          >
            {isLoading ? null : error ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <img src={NoDataFound} alt="No Data Found" />
                <Typography variant="h6">{t("NoDataFound")}</Typography>
              </Box>
            ) : (
              <>
                {questions[currentPage - 1] && (
                  <Box
                    key={questions[currentPage - 1].question_id}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      key={questions[currentPage - 1].question_id}
                      sx={{
                        borderRadius: "8px",
                        marginBottom: "0.5rem",
                        padding: "0.5rem 1rem",
                        display: "flex",
                        alignItems: "center",
                        backgroundColor: "#FFFFFFB2",
                      }}
                    >
                      <img
                        src="/question-vector.svg"
                        alt="Question"
                        style={{
                          width: "42px",
                          height: "42px",
                          marginRight: "16px",
                        }}
                      />
                      <Typography
                        sx={{
                          color: "#3D7C65",
                          fontWeight: "600",
                          textAlign: "left",
                        }}
                      >
                        {questions[currentPage - 1].title || "Mock Question"}
                      </Typography>
                    </Box>
                    <RadioGroup
                      aria-label="answer"
                      name="answer"
                      value={
                        questions[currentPage - 1]?.submitted_answer_details
                          ?.submitted_answer_id || ""
                      }
                      onChange={(e) =>
                        handleSjqAnswer({
                          competency_id:
                            questions[currentPage - 1].competency_id,
                          question_id: questions[currentPage - 1].question_id,
                          answer_id: parseInt(e.target.value),
                          description: questions[currentPage - 1].title,
                        })
                      }
                    >
                      {questions[currentPage - 1].values.map((item) => (
                        <Box
                          key={`answer-${item.answer_id}`}
                          sx={{
                            mt: "0.5rem",
                            backgroundColor: "#FFFFFFB2",
                            padding: "0.5rem 1rem",
                            borderRadius: "8px",
                          }}
                        >
                          <FormControlLabel
                            value={item.answer_id}
                            control={
                              <Radio
                                checked={
                                  item.answer_id ===
                                  questions[currentPage - 1]
                                    ?.submitted_answer_details
                                    ?.submitted_answer_id
                                }
                                sx={{ p: 0.8, color: "#3D7C65" }}
                                size="small"
                              />
                            }
                            label={
                              <Typography
                                sx={{
                                  display: "flex",
                                  color:
                                    item.answer_id ===
                                    questions[currentPage - 1]
                                      ?.submitted_answer_details
                                      ?.submitted_answer_id
                                      ? "#3D7C65"
                                      : "#15134B",
                                  fontWeight: "400",
                                  textAlign: "left",
                                  marginLeft: "8px",
                                }}
                              >
                                {item.value}
                              </Typography>
                            }
                          />
                        </Box>
                      ))}
                    </RadioGroup>
                  </Box>
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>

      <Snackbar
        open={alert.open}
        autoHideDuration={5000}
        onClose={() => setAlert({ open: false, message: "" })}
        sx={{ textAlign: "center" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setAlert({ open: false, message: "" })}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>

      {
        <SJQPaginationFooter
          prevLabel={t("Prev")}
          nextLabel={t("Next")}
          submitLabel={t("Submit")}
          currentPage={parseInt(currentPage)}
          totalPage={parseInt(totalPages)}
          isAssessmentFinished={isSjqFinished}
          onPreviousClick={() => handlePageChange("prev")}
          onNextClick={() => handlePageChange("next")}
          submissionModalState={submissionModalState}
          setSubmissionModalState={setSubmissionModalState}
        />
      }
    </Box>
  );
};

export default SJQ;
