import { Box, Typography } from "@mui/material";
import React from "react";
import ListArrow from "../../assets/ListArrow.png";

const TypographyPoint = ({ text }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          marginTop: "10px 0",
        }}
      >
        <img
          src={ListArrow}
          height={27}
          alt="Bullet point"
          style={{ marginTop: "10px", marginRight: "0.45rem" }}
        />
        <Typography
          sx={{
            color: "#15134B",
            fontWeight: "400",
            marginTop: "10px",
          }}
        >
          {text}
        </Typography>
      </Box>
    </>
  );
};

export default TypographyPoint;
