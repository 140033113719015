import { useCallback, useEffect, useState } from "react";

import i18next from "i18next";
import { useTranslation } from "react-i18next";

import api from "../api/api";
import { GET_KLEAD_COMPETENCY, SET_COMPETENCY_ANS } from "../api/url";

const useKLead = () => {
  const { t } = useTranslation();
  const lang = i18next.language;

  const totalPages = 90;
  const [submissionModalState, setSubmissionModalState] = useState({
    open: false,
    message: t("AssessmentPaused"),
  });
  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: "" });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  const [answeredPages, setAnsweredPages] = useState(new Set());
  const [isKLeadFinished, setIsKLeadFinished] = useState(false);

  const PARTNER_ID = sessionStorage.getItem("PARTNER_ID");

  const fetchKLeadQuestions = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await api.postWithToken(`${GET_KLEAD_COMPETENCY}`, {
        lang,
        partner_id: +PARTNER_ID,
      });

      if (response?.data?.success) {
        const records = response.data.data.records || [];

        if (records.length === 0) {
          setError(t("NoAssessmentFound"));
          return;
        }

        const questions = [];

        let pageNumber = 1;
        let answeredPagesSet = new Set();

        records[0].question_and_page_ids.forEach((question) => {
          questions.push({
            competency_id: records[0].id,
            title: question.title,
            question_id: question.question_id,
            values: question.suggested_answer_ids,
            submitted_answer_details: question.submitted_answer_details,
          });

          if (question.submitted_answer_details.submitted_answer === true) {
            answeredPagesSet.add(pageNumber);
          }

          pageNumber++;
        });

        setAnsweredPages(answeredPagesSet);
        let highestPage = 1;
        if (answeredPagesSet.size > 0) {
          highestPage = Math.max(...Array.from(answeredPagesSet));
        }

        const kLeadCurrentPage = sessionStorage.getItem("KLEAD_CURRENT_PAGE");
        if (kLeadCurrentPage) {
          setCurrentPage(+kLeadCurrentPage);
        } else {
          setCurrentPage(highestPage);
        }

        setQuestions(questions);

        setSuccess(true);
      } else {
        setSuccess(false);
        throw new Error("Failed to fetch data");
      }
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [lang, t, PARTNER_ID]);

  const handlePageChange = useCallback(
    (action) => {
      let newPage = currentPage;

      if (
        action === "next" &&
        currentPage < totalPages &&
        answeredPages.has(currentPage)
      ) {
        newPage = currentPage + 1;
      } else {
        return setAlert({
          open: true,
          message: t("AssessmentPageIncomplete"),
          type: "warning",
        });
      }

      setCurrentPage(newPage);

      sessionStorage.setItem("KLEAD_CURRENT_PAGE", newPage);
    },
    [currentPage, totalPages, answeredPages, t]
  );

  const [requestInProgress, setRequestInProgress] = useState(false);

  const handleKLeadAnswer = useCallback(
    async (data) => {
      if (requestInProgress) return;

      setRequestInProgress(true);
      const { competency_id, question_id, answer_id, description } = data;

      const payload = {
        competency_id,
        question_id,
        answer_id,
        partner_id: PARTNER_ID,
        description,
      };

      try {
        const response = await api.postWithToken(
          `${SET_COMPETENCY_ANS}`,
          payload
        );

        if (response?.data?.success) {
          setQuestions((prev) => {
            const updatedQuestions = [...prev];
            updatedQuestions[currentPage - 1].submitted_answer_details = {
              submitted_answer: true,
              submitted_answer_id: answer_id,
            };
            setTimeout(() => {
              setRequestInProgress(false);
            }, 500);
            return updatedQuestions;
          });

          setAnsweredPages((prev) => new Set([...prev, currentPage]));
        } else {
          setSuccess(false);
          setRequestInProgress(false);
          setAlert({
            open: true,
            message: t("FailedToSaveData"),
            type: "error",
          });
        }
      } catch (error) {
        setSuccess(false);
        setRequestInProgress(false);
        setAlert({ open: true, message: t("FailedToSaveData"), type: "error" });
      }
    },
    [currentPage, PARTNER_ID, t, requestInProgress]
  );

  useEffect(() => {
    setQuestions({});
  }, [lang]);

  useEffect(() => {
    fetchKLeadQuestions();
  }, [fetchKLeadQuestions, lang]);

  useEffect(() => {
    if (answeredPages.has(totalPages)) {
      setIsKLeadFinished(true);
    }
  }, [answeredPages]);

  useEffect(() => {
    return () => {
      sessionStorage.removeItem("KLEAD_CURRENT_PAGE");
    };
  }, []);

  return {
    t,
    isLoading,
    error,
    alert,
    setAlert,
    fetchKLeadQuestions,
    questions,
    currentPage,
    totalPages,
    handlePageChange,
    success,
    handleKLeadAnswer,
    isKLeadFinished,
    submissionModalState,
    setSubmissionModalState,
  };
};

export default useKLead;
