import i18next from "i18next";
import { useCallback, useEffect, useState } from "react";
import api from "../api/api";
import { GET_USERS } from "../api/url";

const useEmployeeData = () => {
  const lang = i18next.language;

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [data, setData] = useState(null);

  const employeeListRequest = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    setSuccess(false);

    const payload = {
      lang,
    };

    try {
      const response = await api.postWithToken(`${GET_USERS}`, payload);
      if (response?.data?.success) {
        setData(response?.data?.data);
        setSuccess(response?.data?.success);
      }
      setIsLoading(false);
    } catch (error) {
      setError(error);
      setIsLoading(false);
    }
  }, [lang]);

  useEffect(() => {
    employeeListRequest();
  }, [employeeListRequest]);

  return {
    isLoading,
    error,
    success,
    data,
  };
};

export default useEmployeeData;
