import { Box, ButtonBase, Typography } from "@mui/material";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import React from "react";
import CustomDialog from "../modals/CustomDialog";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AssignmentPhoto from "../../assets/Assignment.png";
import useFormStatus from "../../hooks/useFormStatus";
import { setActiveKey } from "../../store/Product/action";
import Pagination from "../Pagination";

const BPPaginationFooter = ({
  currentPage,
  totalPage,
  onPreviousClick,
  onNextClick,
  prevLabel = "Prev",
  nextLabel = "Next",
  submitLabel = "Submit",
  isAssessmentFinished,
  submissionModalState,
  setSubmissionModalState,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { formStatusRequest } = useFormStatus();

  const buttonStyle = (disabled) => ({
    border: "1px solid #3D7C65",
    borderRadius: "8px",
    margin: "auto 0",
    padding: "8px",
    width: "170px",
    backgroundColor: disabled ? "inherit" : "#3D7C65",
    color: disabled ? "#3D7C65" : "#fff",
    "&:hover": {
      backgroundColor: disabled ? "inherit" : "#2faa7d",
    },
    mx: 4,
  });

  const textStyle = (disabled) => ({
    color: disabled ? "#3D7C65" : "#fff",
  });

  return (
    <Box
      style={{
        display: "flex",
        position: "fixed",
        bottom: 0,
        left: 0,
        width: "100%",
        justifyContent: "space-between",
        height: "10svh",
        backgroundColor: "#F7FCF4",
      }}
    >
      <ButtonBase
        onClick={onPreviousClick}
        disabled={currentPage === 1}
        sx={buttonStyle(currentPage === 1)}
      >
        <ArrowLeft size="24" color={currentPage === 1 ? "#D4D4D4" : "#fff"} />
        <Typography sx={textStyle(currentPage === 1)} ml={0.5}>
          {prevLabel}
        </Typography>
      </ButtonBase>

      <Pagination currentPage={currentPage} totalPages={totalPage} />

      {isAssessmentFinished && currentPage === totalPage ? (
        <ButtonBase
          onClick={() => {
            formStatusRequest({
              status: "done",
              competency_type: "business_presentation",
            });
            setSubmissionModalState({
              open: true,
              message: t("AssessmentSubmittedSuccessfully"),
            });
          }}
          sx={buttonStyle(true)}
        >
          <Typography sx={textStyle(true)} mr={0.5}>
            {submitLabel}
          </Typography>
        </ButtonBase>
      ) : (
        <ButtonBase
          onClick={onNextClick}
          disabled={currentPage === totalPage}
          sx={buttonStyle(currentPage === totalPage)}
        >
          <Typography sx={textStyle(currentPage === totalPage)} mr={0.5}>
            {nextLabel}
          </Typography>
          {!isAssessmentFinished && (
            <ArrowRight
              size="24"
              color={currentPage === totalPage ? "#D4D4D4" : "#fff"}
            />
          )}
        </ButtonBase>
      )}

      <CustomDialog
        open={submissionModalState.open}
        title={t("AssessmentSubmittedSuccessfully")}
        children={
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              padding: "2rem 0",
              justifyContent: "center",
              gap: "2rem",
            }}
          >
            <img src={AssignmentPhoto} height={150} alt="Assignment" />

            <Typography variant="h6" align="center">
              {submissionModalState.message || t("CompeletedAssessment")}
            </Typography>

            <ButtonBase
              sx={{
                ...buttonStyle(true),
                width: "auto",
                padding: "1rem 2rem",
              }}
              onClick={() => {
                setSubmissionModalState({
                  open: false,
                  message: t("AssessmentSubmittedSuccessfully"),
                });
                dispatch(setActiveKey(0));

                navigate("/dashboard/forms", { replace: true });
              }}
            >
              {t("GoHomepage")}
            </ButtonBase>
          </Box>
        }
      />
    </Box>
  );
};

export default BPPaginationFooter;
