import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useEffect, useState } from "react";

const ProficiencyLevelDropdown = ({
  t,
  title,
  hintText,
  proficiencyCount,
  selectedValue,
  handleChange,
  disabled,
  competencyId,
  questionId,
  handlePsuedoChange,
}) => {
  const normalizedSelectedValue = selectedValue > 0 ? selectedValue : 0;

  const [ubaPart, setUbaPart] = useState("");

  useEffect(() => {
    const part = sessionStorage.getItem("UBA_GLOSSARY_PART");

    setUbaPart(part);
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
        gap: "10px",
        padding: "0.5rem 1rem",
        borderRadius: "8px",
        backgroundColor: "#FFFFFFB2",
      }}
    >
      {hintText && (
        <Typography
          sx={{
            color: "#3D7C65",
            fontWeight: "600",
          }}
        >
          {hintText}
        </Typography>
      )}

      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Typography
          sx={{
            borderRadius: "4px",
            padding: "8px 0",
            color: "#3D7C65",
            fontWeight: "bold",
          }}
        >
          {title}:
        </Typography>

        <FormControl>
          <Select
            value={normalizedSelectedValue}
            onChange={
              !!handleChange
                ? handleChange
                : (e) =>
                    handlePsuedoChange(competencyId, questionId, e.target.value)
            }
            disabled={disabled}
            sx={{
              width: {
                lg: "400px",
              },
              height: "45px",
              "&.MuiOutlinedInput-root.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                {
                  border: "1px solid #0f0f0f4dl",
                },
              "&.MuiOutlinedInput-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                  borderColor: "black",
                },
              "& .MuiSelect-iconOutlined": {
                color: "black",
              },
            }}
          >
            <MenuItem key={0} value={0} disabled>
              {ubaPart ? t("SPL") : title}
            </MenuItem>
            {Array.from({ length: proficiencyCount }, (_, index) => (
              <MenuItem key={index + 1} value={index + 1}>
                {ubaPart === "1"
                  ? t(
                      `${
                        index === 0
                          ? "P1Novice"
                          : index === 1
                          ? "P1Practitioner"
                          : index === 2
                          ? "P1Proficient"
                          : index === 3
                          ? "P1Expert"
                          : "P1Champion"
                      }`
                    )
                  : ubaPart === "2"
                  ? t(
                      `${
                        index === 0
                          ? "P2Execution"
                          : index === 1
                          ? "P2Application"
                          : index === 2
                          ? "P2Advanced"
                          : index === 3
                          ? "P2Coach"
                          : "P2Mastery"
                      }`
                    )
                  : null}{" "}
                {!!ubaPart
                  ? `(${t("PL")} ${index + 1})`
                  : `${t("PL")} ${index + 1}`}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ProficiencyLevelDropdown;
