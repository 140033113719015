import axios from "axios";
import { STORAGEKEY } from "./index";
import { URL } from "./url";

export const Bearer = "Bearer ";

// Create Axios instance
const instance = axios.create({
  baseURL: URL,
});

// Add a request interceptor
const api = {
  get: (url, data) => {
    return instance.get(url, data);
  },

  post: (url, data) => {
    return instance.post(url, data);
  },

  put: (url, data) => {
    return instance.put(url, data);
  },

  postWithToken: (url, data) => {
    const token = sessionStorage.getItem(STORAGEKEY.ACCESSTOKEN);
    if (!!token) {
      return instance.post(url, data, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }
  },

  getWithParams: (url, params) => {
    return instance.get(url, {
      params,
    });
  },

  getWithToken: (url) => {
    return instance.get(url);
  },

  getWithTokenTelegram: (url) => {
    return instance.get(url);
  },
  putWithToken: (url, data) => {
    return instance.put(url, data);
  },

  deleteWithToken: (url, params) => {
    return instance.delete(url, {
      params,
    });
  },

  deleteWithTokenData: (url, data) => {
    return instance.delete(url, {
      data: data,
    });
  },
};

export default api;
