import i18next from "i18next";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "../api/api";
import { GET_COMPETENCY, SET_ASSESSMENT_ANS } from "../api/url";
import useSubmissionTimer from "./useSubmissionTimer";
import useUserData from "./useUserData";

const useFeedback360Degree = () => {
  const { t } = useTranslation();
  const { data } = useUserData();
  const {
    timerRequest,
    error: timerUpdateError,
    success: timerUpdateSuccessful,
    data: timerData,
  } = useSubmissionTimer();

  const [noRecords, setNoRecords] = useState(false);
  const lang = i18next.language;

  const [startTime, setStartTime] = useState(new Date().toISOString());
  const [mounted, setMounted] = useState(false);
  const [timestamp, setTimestamp] = useState(3600);
  const [submissionModalState, setSubmissionModalState] = useState({
    open: false,
    message: t("AssessmentPaused"),
  });
  const [competencyMetadata, setCompetencyMetadata] = useState({});
  const [competencies, setCompetencies] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState({ open: false, message: "" });
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [selfAssessmentData, setSelfAssessmentData] = useState({});
  const [answeredPages, setAnsweredPages] = useState(new Set());
  const [isAssessmentFinished, setIsAssessmentFinished] = useState(false);

  const PARTNER_ID = sessionStorage.getItem("PARTNER_ID");

  const fetchCompetencies = useCallback(
    async (page) => {
      // Calculate offset directly from the page number and items per page
      const itemsPerPage = 1; // Adjust items per page as needed
      const offset = (page - 1) * itemsPerPage;

      // If the data for the current page is already in the state, return
      if (competencies[page]) {
        return;
      }

      setIsLoading(true);
      setError(null);

      try {
        const response = await api.postWithToken(`${GET_COMPETENCY}`, {
          limit: itemsPerPage,
          offset,
          lang,
          pl_wise: true,
          partner_id: +PARTNER_ID,
        });

        if (response?.data?.success) {
          const records = response.data.data.records || [];

          if (records.length === 0) {
            setError(t("NoAssessmentFound"));
            return;
          }

          const newCompetencies = {};
          const newMetadata = {};

          records.forEach((record) => {
            const activePageKey = page;
            const fetchedCompetencies = record["question_and_page_ids"] || [];
            newCompetencies[activePageKey] = fetchedCompetencies;

            newMetadata[activePageKey] = {
              id: record.id,
              name: record.name,
              definition: record.competency_definition,
              expectedScore: record.score,
              selfAssessedScore: record.submitted_answer_details?.score
                ? parseInt(record.submitted_answer_details?.score)
                : null,
            };

            const currentPageKey = activePageKey;
            if (record.submitted_answer_details?.score) {
              setSelfAssessmentData((prev) => ({
                ...prev,
                [currentPageKey]: parseInt(
                  record.submitted_answer_details.score,
                  10
                ),
              }));
              setAnsweredPages((prev) => new Set([...prev, currentPageKey]));
            }
          });

          // Check if the current page matches the fetched page before updating state
          if (currentPage === page) {
            setCompetencies((prev) => ({ ...prev, ...newCompetencies }));
            setCompetencyMetadata((prev) => ({ ...prev, ...newMetadata }));
          }

          const totalRecords = response.data.data.total_records;
          setTotalPages(Math.ceil(totalRecords));
          setNoRecords(totalRecords === 0 ? true : false);
          setSuccess(true);
          // Update the last fetched page
        } else {
          setSuccess(false);
          throw new Error("Failed to fetch data");
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    },
    [competencies, lang, t, PARTNER_ID, currentPage]
  );

  const handlePageChange = useCallback(
    (action) => {
      let newPage = currentPage;

      if (action === "prev" && currentPage > 1) {
        newPage = currentPage - 1;
      } else if (
        action === "next" &&
        currentPage < totalPages &&
        answeredPages.has(currentPage)
      ) {
        newPage = currentPage + 1;
      } else {
        return setAlert({
          open: true,
          message: t("AssessmentPageIncomplete"),
          type: "warning",
        });
      }

      setCurrentPage(newPage);
    },
    [currentPage, totalPages, answeredPages, t]
  );

  const [requestInProgress, setRequestInProgress] = useState(false);

  const handleSelfAssessment = useCallback(
    async (data) => {
      if (competencyMetadata === undefined || requestInProgress) return;

      setRequestInProgress(true);

      const payload = {
        competency_id: competencyMetadata[currentPage].id,
        answer_score: data,
        partner_id: PARTNER_ID,
      };

      const response = await api.postWithToken(
        `${SET_ASSESSMENT_ANS}`,
        payload
      );

      if (response?.data?.success) {
        setSelfAssessmentData((prev) => ({
          ...prev,
          [currentPage]: data,
        }));
        setAnsweredPages((prev) => new Set([...prev, currentPage]));

        // Set a timeout of 5 seconds before allowing another request
        setTimeout(() => {
          setRequestInProgress(false);
        }, 500);
      } else {
        setRequestInProgress(false);
        setSuccess(false);
        setAlert({ open: true, message: t("FailedToSaveData"), type: "error" });
      }
    },
    [currentPage, competencyMetadata, PARTNER_ID, t, requestInProgress]
  );

  useEffect(() => {
    setMounted(true);

    return () => {
      setMounted(false);
    };
  }, []);

  useEffect(() => {
    if (!mounted) return;
    setCompetencyMetadata({});
    setCompetencies({});
  }, [mounted, lang]);

  useEffect(() => {
    if (!mounted) return;
    fetchCompetencies(currentPage);
  }, [mounted, fetchCompetencies, currentPage, lang]);

  useEffect(() => {
    if (!mounted) return;
    if (answeredPages.has(totalPages)) {
      setIsAssessmentFinished(true);
    }
  }, [mounted, answeredPages, totalPages]);

  // useEffect(() => {
  //   if (!mounted) return;
  //   const interval = setInterval(() => {
  //     setTimestamp((prev) => Math.max(0, prev - 1));
  //   }, 1000);

  //   return () => clearInterval(interval);
  // }, [mounted, data]);

  // useEffect(() => {
  //   if (!mounted) return;
  //   if (data?.total_time_spent) {
  //     const [minutes, seconds] = data.total_time_spent.split(":");
  //     const timeSpentInSeconds = parseInt(minutes) * 60 + parseInt(seconds);
  //     setTimestamp((prev) => Math.max(0, 3600 - timeSpentInSeconds));
  //   }
  // }, [mounted, data]);

  // useEffect(() => {
  //   if (!mounted) return;
  //   const saveElapsedTime = () => {
  //     const endTime = new Date().toISOString();
  //     timerRequest({
  //       startTime: startTime,
  //       endTime: endTime,
  //     });

  //     setStartTime(endTime);
  //   };

  //   // Run the interval function every minute
  //   const interval = setInterval(saveElapsedTime, 30000);

  //   return () => clearInterval(interval);
  // }, [mounted, timerRequest, startTime]);

  // Handle form close event
  // useEffect(() => {
  //   if (!mounted) return;
  //   const handleBeforeUnload = (e) => {
  //     e.preventDefault();

  //     const endTime = new Date().toISOString();
  //     timerRequest({
  //       startTime: startTime,
  //       endTime: endTime,
  //     });

  //     setStartTime(endTime);
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [mounted, timerRequest, t, startTime]);

  return {
    t,
    isLoading,
    error,
    alert,
    noRecords,
    setAlert,
    fetchCompetencies,
    competencies,
    competencyMetadata,
    currentPage,
    totalPages,
    handlePageChange,
    success,
    startTime,
    timestamp,
    selfAssessmentData,
    handleSelfAssessment,
    isAssessmentFinished,
    submissionModalState,
    setSubmissionModalState,
  };
};

export default useFeedback360Degree;
