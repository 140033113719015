export const PROD_URL = "https://api.runmyassessment.com/api/v1/";
export const DEV_URL = "https://devapi.runmyassessment.com/api/v1/";

export const LOGIN_URL = "login/";
export const GET_USER_INFO = "get_user_info/";
export const GET_USERS = "get_users/";
export const GET_COMPETENCY = "get_competency/";
export const SET_COMPETENCY_ANS = "submit_competency_answer/";
export const SET_ASSESSMENT_ANS = "submit_competency_answer_pl_wise/";
export const SET_PROFICIENCY_ANS = "submit_competency_proficiency_answer/";
export const SET_ASSESSMENT_TIMER = "feedback_time_spent_employee_wise/";
export const SET_SUBMIT_FEEDBACK_FORM = "submit_feedback_status_employee_wise/";
export const GET_SJQ_QUESTIONS = "get_sjq_competency/";
export const GET_UBA_COMPETENCY = "get_uba_competency/";
export const GET_KLEAD_COMPETENCY = "get_klead_competency/";
export const GET_BP_RESPONSE = "get_business_presentation_competency/";

// export const URL =
//   process.env.NODE_ENV === "development"
//     ? "https://devapi.runmyassessment.com/api/v1/"
//     : `https://api.runmyassessment.com/api/v1/`;

export const URL = `https://devapi.runmyassessment.com/api/v1/`;
